import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as LZString from 'lz-string';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.prod';

const encrypt = (() => {
  const k1 =
    'EPiK47ft=9hlW8Db20pgBwX6UkjYAOLsVINnSFrdmqT/QaCcyeJG513uZ+xRMozvH';
  const secret = Object.fromEntries(
    '8DbsUEPiKyeJGlWk2VrdqT0hYaCS/vHZ+xRmQ9ozAF147fcOpg=MBwX6LINn3u5jt'
      .split('')
      .map((v, i) => [v, k1[i]])
  );
  return (base64: string) =>
    base64
      .split('')
      .map((v) => secret[v])
      .join('')
      .replace(/\//g, '_')
      .replace(/\+/g, '-');
})();

@Injectable()
export class JDataInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.production && req.params.has('jData')) {
      let jData = req.params.get('jData');
      jData = LZString.compressToBase64(jData);
      jData = encrypt(jData);
      const zipReq = req.clone({
        params: req.params.delete('jData').set('jData.ze', jData),
      });
      return next.handle(zipReq);
    }
    return next.handle(req);
  }
}
