import { Routes } from '@angular/router';
import { UserLoginGuard } from './guards/user-login.guard';
import { BlankLayoutComponent } from './layouts/blank/blank-layout.component';
import { NkMainComponent } from './layouts/panel/main/main.component';
import { MainRoutes } from './routes/main.routing';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    component: BlankLayoutComponent,
    loadChildren: () =>
      import('./pages/auth/auth.module').then((v) => v.AuthModule),
  },

  {
    path: '',
    component: NkMainComponent,
    children: MainRoutes,
    canActivate: [UserLoginGuard],
  },
];
