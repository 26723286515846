export const COMMENT_LIST = '[COMMENT LIST]';
export const TALK_LIST = '[TALK LIST]';
export const NewsView = '[News View]';
export const NewsCreate = '[News Create]';

// NEWS LIST
// -=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=-

export class UpdateFilter {
  static readonly type = COMMENT_LIST + ' UpdateFilter';
  constructor(public payload: any) {}
}

export class ResetFilter {
  static readonly type = COMMENT_LIST + ' ResetFilter';
  constructor() {}
}

export class UpdateConfig {
  static readonly type = COMMENT_LIST + ' UpdateConfig';
  constructor(public payload: any) {}
}

export class ResetConfig {
  static readonly type = COMMENT_LIST + ' ResetConfig';
  constructor() {}
}

export class AddComment {
  static readonly type = COMMENT_LIST + ' AddComment';
  constructor(public payload: { room: string; comments: any[] }) {}
}
export class UpdateMeta {
  static readonly type = COMMENT_LIST + ' UpdateMeta';
  constructor(public payload: { room: string; meta: object }) {}
}

export class AddRoom {
  static readonly type = TALK_LIST + ' AddRoom';
  constructor(public payload: string, public force = false) {}
}

// export class AddBoom {
//   static readonly type = TALK_LIST + ' AddRoom';
//   constructor(public payload: string) {}
// }
