import {
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import {
  NavigationLayoutSettingStore,
  ToggleNavigationOpen,
} from '../../../stores/app/navbar.state';
import { SideMenuStore } from '../../../stores/app/side-menu.state';
import { AppThemeStore } from '../../../stores/app/theme.state';

@Component({
  selector: 'nk-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NkMainComponent implements OnInit, OnDestroy {
  @HostBinding('attr.nk-layout-mode') public layoutMode;

  public sideBarConfig: any;
  public nkSettings: any;
  public navigation: any;

  @Select(AppThemeStore.appThemeConfig)
  private appThemeConfig$: Observable<any>;

  @Select(NavigationLayoutSettingStore.navLayoutConfig)
  private sideBarConfig$: Observable<any>;

  @Select(SideMenuStore.navMenu)
  public navMenu$: Observable<any>;

  private onConfigChangedSubscription: Subscription;
  private onSideBarConfigChangedSubscription: Subscription;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly store: Store
  ) {}

  public ngOnInit() {
    this.onConfigChangedSubscription = this.appThemeConfig$.subscribe(
      (newSettings) => {
        this.nkSettings = newSettings;
        this.layoutMode = this.nkSettings.layout.mode;
      }
    );

    this.onSideBarConfigChangedSubscription = this.sideBarConfig$.subscribe(
      (v) => (this.sideBarConfig = v)
    );
    // this.navigation = navigation;
  }

  public ngOnDestroy() {
    this.onConfigChangedSubscription?.unsubscribe();
    this.onSideBarConfigChangedSubscription?.unsubscribe();
  }

  private addClass(className: string) {
    this.renderer.addClass(this.elementRef.nativeElement, className);
  }

  private removeClass(className: string) {
    this.renderer.removeClass(this.elementRef.nativeElement, className);
  }

  public navbarBackDropClicked() {
    this.store.dispatch(new ToggleNavigationOpen());
  }
}
