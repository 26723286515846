import { animate, style, transition, trigger } from '@angular/animations';

export const jellyAnimation = trigger('jelly', [
  transition('* => *', [
    style({ transform: 'scale(1.1)' }),
    animate(
      '300ms cubic-bezier(0.0, 0.0, 0.2, 1)',
      style({ transform: 'scale(1)' })
    ),
  ]),
]);
