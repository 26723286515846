import { NgModule } from '@angular/core';
import { CamelCaseToDashPipe, DashToTitle } from './camelCaseToDash.pipe';
import { FromNowPipe } from './from-now.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { EntriesPipe, KeysPipe } from './keys.pipe';
import { SvgIconUrlPipe } from './svgIconUrl.pipe';

const modules = [
  // FilterPipe,
  CamelCaseToDashPipe,
  DashToTitle,
  EntriesPipe,
  GetByIdPipe,
  HtmlToPlaintextPipe,
  KeysPipe,
  SvgIconUrlPipe,
  FromNowPipe,
];
@NgModule({ declarations: modules, exports: modules })
export class NkPipesModule {}
