<button
  mat-icon-button
  type="button"
  class="mat-elevation-z1"
  [matMenuTriggerFor]="colorMenu"
  (menuOpened)="onMenuOpen()"
  [ngClass]="'mat-'+selectedPalette+'-'+selectedHue+'-bg'"
>
  <mat-icon>palette</mat-icon>
</button>
<mat-menu
  #colorMenu="matMenu"
  class="nk-material-color-picker-menu"
>
  <header
    [ngClass]="selectedColor?.class || 'mat-accent-bg'"
    (click)="$event.stopPropagation();$event.preventDefault()"
    class="mat-elevation-z4"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <button
      mat-icon-button
      [style.visibility]="view==='hues'?'visible':'hidden'"
      (click)="$event.stopPropagation();backToPaletteSelection()"
      aria-label="Palette"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span *ngIf="selectedColor?.palette">
      {{selectedColor.palette | dashToTitle}} {{selectedColor.hue}}
    </span>
    <span *ngIf="!selectedColor?.palette">Select Color</span>
    <button
      mat-icon-button
      class="remove-color-button"
      (click)="$event.stopPropagation();removeColor()"
      aria-label="Remove Color"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </header>
  <div
    class="views"
    (click)="$event.stopPropagation();$event.preventDefault()"
  >
    <div
      class="view"
      [style.transform]="'translateX('+(view==='hues'?'-100%':'0')+')'"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        class="colors h100p"
      >
        <div
          class="color"
          *ngFor="let color of (colors | keys)"
          [ngClass]="'mat-'+color.key+'-bg'"
          (click)="$event.stopPropagation();selectPalette(color.key)"
          fxLayout="row"
          fxLayoutAlign="start end"
          mat-ink-ripple
        >
          <!-- <span class="label">{{color.key}}</span> -->
        </div>
      </div>
    </div>
    <div
      class="view"
      [style.transform]="'translateX('+(view!=='hues'?'100%':'0')+')'"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        class="colors h100p"
      >
        <div
          class="color"
          *ngFor="let hue of getHueFromPalette(selectedPalette)"
          [class.br50p]="(selectedHue||'500') == hue"
          [class]="'mat-'+selectedPalette+'-'+hue+'-bg'"
          (click)="$event.stopPropagation();selectHue(hue)"
          fxLayout="row"
          fxLayoutAlign="start end"
          mat-ink-ripple
        >
          <!-- <span class="label">{{hue}}</span> -->
          <!-- <mat-icon
            *ngIf="((selectedHue||'500') === hue)"
            class="s16"
          >check</mat-icon> -->
        </div>
      </div>
    </div>
  </div>
</mat-menu>
