import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { NkMatchMediaService } from '../../services/app-services/match-media.service';
import { wait } from '../../shared/fun';
import { AppThemeStore } from '../../stores/app/theme.state';
import { NkNavigationService } from '../navigation/navigation.service';
import { ShortcutsItems } from './shortcuts.items';

@Component({
  selector: 'nk-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss'],
})
export class NkShortcutsComponent implements OnInit, OnDestroy {
  shortcutItems: any[] = [];
  navigationItems: any[];
  filteredNavigationItems: any[];
  searching = false;
  mobileShortcutsPanelActive = false;
  toolbarColor: string;
  matchMediaSubscription: Subscription;
  onConfigChanged: Subscription;

  @Input() navigation: any;

  @ViewChild('searchInput', { static: true }) searchInputField;
  @ViewChild('shortcuts', { static: true }) shortcutsEl: ElementRef;

  @Select(AppThemeStore.appThemeConfig)
  private appThemeConfig$: Observable<any>;

  constructor(
    private renderer: Renderer2,
    private observableMedia: MediaObserver,
    private nkMatchMedia: NkMatchMediaService,
    private nkNavigationService: NkNavigationService
  ) {
    this.onConfigChanged = this.appThemeConfig$.subscribe((newSettings) => {
      this.toolbarColor = newSettings.colorClasses.toolbar;
    });
  }

  ngOnInit() {
    // Get the navigation items and flatten them
    this.filteredNavigationItems = this.navigationItems = this.nkNavigationService.getFlatNavigation(
      this.navigation
    );

    // const cookieExists = this.cookieService.check('NK2.shortcuts');
    const cookieExists = localStorage.getItem('NK2.shortcuts');

    if (cookieExists) {
      this.shortcutItems = JSON.parse(
        // this.cookieService.get('NK2.shortcuts')
        localStorage.getItem('NK2.shortcuts')
      );
    } else {
      // User's shortcut items
      this.shortcutItems = ShortcutsItems;
    }

    this.matchMediaSubscription = this.nkMatchMedia.onMediaChange.subscribe(
      () => {
        if (this.observableMedia.isActive('gt-sm')) {
          this.hideMobileShortcutsPanel();
        }
      }
    );
  }

  ngOnDestroy() {
    this.matchMediaSubscription?.unsubscribe();
    this.onConfigChanged?.unsubscribe();
  }

  search(value) {
    value = value.toLowerCase();

    // if (value === '') {
    //   this.searching = false;
    //   this.filteredNavigationItems = this.navigationItems;

    //   return;
    // }

    this.searching = true;

    this.filteredNavigationItems = this.navigationItems.filter(
      (navigationItem) => {
        return navigationItem.title.toLowerCase().includes(value);
      }
    );
  }

  toggleShortcut(event, itemToToggle) {
    event.stopPropagation();

    for (let i = 0; i < this.shortcutItems.length; i++) {
      if (this.shortcutItems[i].id === itemToToggle.id) {
        this.shortcutItems.splice(i, 1);

        // Save to the cookies
        // this.cookieService.set(
        localStorage.setItem(
          'NK2.shortcuts',
          JSON.stringify(this.shortcutItems)
        );

        return;
      }
    }

    this.shortcutItems.push(itemToToggle);

    // Save to the cookies
    // this.cookieService.set(
    localStorage.setItem('NK2.shortcuts', JSON.stringify(this.shortcutItems));
  }

  isInShortcuts(navigationItem) {
    return this.shortcutItems.find((item) => {
      return item.id === navigationItem.id;
    });
  }

  async onMenuOpen() {
    await wait(10);
    this.searchInputField.nativeElement.focus();
  }

  showMobileShortcutsPanel() {
    this.mobileShortcutsPanelActive = true;
    this.renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
  }

  hideMobileShortcutsPanel() {
    this.mobileShortcutsPanelActive = false;
    this.renderer.removeClass(
      this.shortcutsEl.nativeElement,
      'show-mobile-panel'
    );
  }
}
