import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Routes } from '../configs/api.router.config';
import { JData } from '../interfaces/j-data.interface';
import { LoginUserDetails } from '../models/login-module';
import { TEMP_CACHE } from './temp-cache.service';
import { UserGlobalService } from './user-global.service';

@Injectable()
export class UsersService {
  // private readonly user = null;
  private readonly token = localStorage.getItem('poiuytrewq');
  private domains = 'APP_DOMAINS';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly userGlobalService: UserGlobalService
  ) {}

  public getUser(jData: JData) {
    // console.log(this.token);
    const params = new HttpParams().append('jData', JSON.stringify(jData));
    return this.httpClient.get(Routes.getUserList, {
      params,
    });
  }
  public getRole() {
    return this.httpClient.get(Routes.getRoles, {});
  }

  public updatePic(data: FormData, id: any) {
    return this.httpClient.post(Routes.UpdatePic({ id }), data, {});
  }
  public updateProfile(
    id: any,
    data: {
      basicInfo: {
        email: any;
        password: any;
        username: any;
        mobile: any;
        name: any;
        meta: {};
      };
      profileInfo: {
        userId: any;

        companyName: any;
        businessOwner: any;
        companyEmail: any;
        contactDetails: any;
        website: any;
        solutions: any;
        isSell: any;
        country: any;
        isProvides: any;
        companyAddress: any;
        clients: any;
        desc: any;
        needs: any;
        capable: any;
      };
    }
  ) {
    console.log(data);
    return this.httpClient.post(Routes.updateProfile({ id }), data, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    });
  }
  public updateUserProfile(id: any, data: any) {
    return this.httpClient.post(Routes.updateUserProfile({ id }), data, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    });
  }
  public getRoleId(id: any) {
    return this.httpClient.post(Routes.getRoleId({ id }), {
      headers: {
        Accept: 'application/json',
      },
    });
  }

  public paymentMode(data) {
    return this.httpClient.post(Routes.paymentMode, data, {}).pipe();
  }
  public updateRole(id: any, data: any) {
    return this.httpClient.post(Routes.updateRole({ id }), data, {
      headers: {
        Accept: 'application/json',
        //Authorization: `Bearer ${this.token}`,
      },
    });
  }
  public updateSubscribe(id: any, data: any) {
    return this.httpClient.post(Routes.updateSubscribe({ id }), data, {
      headers: {
        Accept: 'application/json',
      },
    });
  }

  public getPaymentMode() {
    return this.httpClient.get(Routes.getPaymentMode, {});
  }

  public getSingleUser(id: any) {
    return this.httpClient.get(Routes.getSingleUser({ id }), {});
    // .pipe(map((data) => new Message(data)));
  }

  public getAboutUser(id: any) {
    return this.httpClient.get(Routes.getAboutUser({ id }), {});
    // .pipe(map((data) => new Message(data)));
  }
  public sendToken(data: any) {
    console.log(data, 'hello');
    return this.httpClient.post(Routes.sendToken, data, {});
  }

  public setEmail(data: any) {
    return this.httpClient.post(Routes.SetEmail, data, {});
  }
  public getSingleUserProfile() {
    return this.httpClient.get(Routes.getSingleUserProfile, {}).pipe(
      map((data) => new LoginUserDetails(data)),
      tap((data) => this.userGlobalService.updatePermissions(data)),
      tap((userDetails) => (this.userGlobalService.user = userDetails))
    );
  }

  public deleteUser(id: string | number) {
    return this.httpClient.delete(Routes.DeleteUser({ id }), {});
  }

  public createReview(data: any) {
    console.log(data);
    return this.httpClient.post(Routes.createReview, data, {});
  }

  public getReview(id: string | number, jData: JData) {
    console.log(jData);
    const params = new HttpParams().append('jData', JSON.stringify(jData));

    return this.httpClient.get(Routes.getReview({ id }), {
      params,
    });
  }
  public getActivity(id: string | number, jData: JData) {
    console.log(jData);
    const params = new HttpParams().append('jData', JSON.stringify(jData));

    return this.httpClient.get(Routes.getActivity({ id }), {
      params,
    });
  }

  public getDomains() {
    if (TEMP_CACHE.has(this.domains)) {
      return of(TEMP_CACHE.get(this.domains));
    }
    return this.httpClient
      .get(Routes.getDomains)
      .pipe(tap((v) => TEMP_CACHE.set(this.domains, v)));
  }

  public createByAdmin(userData) {
    return this.httpClient.post(Routes.createByAdmin, userData, {}).pipe();
  }
}

export const reload = new Subject();
export const reloadName = new Subject();
