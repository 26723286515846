import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SnackBarService } from '../../services/app-services/snack-bar.service';
import { reload, reloadName, UsersService } from '../../services/users.service';
import { ImageService } from '../../services/utility/image.service';
import { equalTo } from '../../shared/field-validators';
import { dataURLToFile } from '../../shared/file.fun';
import { LetterAvatar } from '../../shared/letter-avatar';
import { MatColors } from '../../shared/mat-colors';
import { EmailTokenComponent } from '../eamil-token/email-token.component';

@Component({
  selector: 'nk-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  private data;
  private image;
  public errorList: any;
  public fb: any;
  public hide = true;
  public mySubscription: any;
  public profileData: any;
  public refresh$ = new Subject();
  public roles: any;
  public selectRole = [];
  public showLoadingBar: boolean;
  public updateProfileForm: FormGroup;
  public updateRoleForm: FormGroup;
  public url: any;
  public useDefault = false;
  //public disabled = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) readonly modalData: any,
    private readonly matDialogRef: MatDialogRef<UserProfileComponent>,

    private readonly usersService: UsersService,

    private readonly imageService: ImageService,
    private readonly snackService: SnackBarService,
    private readonly dialogService: MatDialog
  ) {
    this.profileData = modalData.profileData.data;
    console.log(this.profileData.meta.login);
    this.fb = this.profileData.meta.login;
  }

  ngOnInit(): void {
    this.updateForm();
    this.initializeProfilePic();
  }
  public updateForm() {
    const password = new FormControl(
      { value: '', disabled: this.fb },
      Validators.compose([Validators.minLength(8), Validators.maxLength(20)])
    );
    const confirmPassword = new FormControl(
      { value: '', disabled: this.fb },
      Validators.compose([equalTo(password)])
    );
    const oldPassword = new FormControl(
      { value: '', disabled: this.fb },
      Validators.compose([Validators.minLength(8), Validators.maxLength(20)])
    );
    const gender = new FormControl(
      this.profileData.gender,
      Validators.compose([])
    );

    const name = new FormControl(
      this.profileData.name,
      Validators.compose([Validators.required])
    );

    const mobile = new FormControl(
      this.profileData.mobile,
      Validators.compose([])
    );

    this.updateProfileForm = new FormGroup({
      email: new FormControl(
        // this.profileData.email,
        { value: this.profileData.email, disabled: this.fb },
        Validators.compose([Validators.email, Validators.required])
      ),
      username: new FormControl(
        this.profileData.username,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[A-z][A-z0-9_]{5,11}/),
        ])
      ),
      password: password,
      gender: gender,
      mobile: mobile,
      name: name,
      oldPassword: oldPassword,
      confirmPassword: confirmPassword,
    });

    this.initializeProfilePic();
  }

  private async initializeProfilePic() {
    if (this.profileData.pic) {
      return;
    }
    const colors = Object.keys(MatColors.getAll()).filter(
      (v) => !['black', 'white'].includes(v)
    );
    const color = colors[Math.floor(Math.random() * colors.length)];
    const matColor = MatColors.getAll()[color]['500'];
    const url = LetterAvatar({
      name: this.profileData.name,
      background: matColor['0'],
      color: matColor['1'],
    });
    this.url = url;
    this.image = await dataURLToFile(url, 'avatar.png', 'image/png');
  }

  public updateProfile() {
    this.data = this.updateProfileForm.value;
    console.log(this.data);
    if (this.data.mobile === '') {
      this.data.mobile = undefined;
    }
    if (this.data.gender === '') {
      this.data.gender = undefined;
    }
    this.data.meta = {};
    if (this.data.password && !this.data.oldPassword) {
      this.snackService.error('please enter the old password');
      return;
    }
    if (!this.fb) {
      if (this.profileData.email == this.data.email) {
        this.usersService
          .updateUserProfile(this.profileData.id, this.data)
          .subscribe(
            (result) => {
              if (this.image) {
                this.confirm();
                console.log(result);
                reload.next({ pic: this.url, name: this.data.name });
              }

              this.snackService.success('Profile update successfully');
            },
            (error) => {
              const a = error.error;
              this.snackService.error(
                Array.isArray(a.message) ? a.message.join('\n') : a.message
              );
              // console.log(error);
            }
          );
      } else {
        this.data = this.updateProfileForm.value;
        console.log(this.data);
        //this.loader.show();
        this.usersService
          .sendToken(
            this.data
            // this.loginForm.controls['password'].value
          )
          .subscribe(
            (result) => {
              // console.log(result);
              //this.router.navigate(['home']);
              // this.loader.hide();
              // this.snackService.info(
              //   'Please Check your email for the next process and token time only 10 minutes'
              // );
              // this.show = true;
            },
            (error) => {
              const a = error;
              console.log(a.error.message);
              this.snackService.error(a.error.message);
            }
          );

        const dialogRef = this.dialogService.open(EmailTokenComponent, {
          data: {
            profileData: this.data.email,
          },
          width: '400px',
        });
      }
    } else {
      this.usersService
        .updateUserProfile(this.profileData.id, this.data)
        .subscribe(
          (result) => {
            if (this.image) {
              this.confirm();
              console.log(result);
              reload.next({ pic: this.url });
            }
            if (this.data.name) {
              reloadName.next({ name: this.data.name });
            }
            this.snackService.success('profile update successfully');
          },
          (error) => {
            const a = error;
            console.log(a.error.message);
            this.snackService.error(a.error.message);
            // console.log(error);
          }
        );
    }

    this.data.pic = this.url;
    this.matDialogRef.close(true);
  }

  public async updatePhoto(event) {
    if (!event.target.files?.length) {
      return;
    }

    const file: File = event.target.files[0];
    const imageURL = await this.imageService.uploadThumbImage(file);
    if (!imageURL) {
      return;
    }
    this.image = await dataURLToFile(imageURL, file.name, file.type);
    this.url = imageURL;
  }
  public toggle(event: any) {
    console.log('toggle', event.checked);
    this.useDefault = event.checked;
  }

  public confirm() {
    const formData = new FormData();
    formData.append('pic', this.image);
    this.usersService.updatePic(formData, this.profileData.id).subscribe(
      (result) => {
        // this.snackService.success('profile update successfully');
        this.refresh$.next();
        this.matDialogRef.close(true);
      },
      (error) => {
        this.snackService.error('Something went wrong');
      }
    );
  }
}
