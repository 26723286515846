import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NkMatSidenavHelperService } from '../services/app-services/sidenav.service';

@Directive({ selector: '[nkMatSidenavHelper]' })
export class NkMatSidenavHelperDirective implements OnInit, OnDestroy {
  // private readonly matchMediaSubscription!: Subscription;
  @HostBinding('class.mat-is-locked-open') isLockedOpen = true;
  @Input('nkMatSidenavHelper') id!: string;
  @Input('mat-is-locked-open') matIsLockedOpenBreakpoint!: string;

  constructor(
    private readonly nkMatSidenavService: NkMatSidenavHelperService,
    // private readonly nkMatchMedia: NkMatchMediaService,
    // private readonly observableMedia: MediaObserver,
    private readonly matSidenav: MatSidenav
  ) {}

  ngOnInit() {
    this.nkMatSidenavService.setSidenav(this.id, this.matSidenav);

    // if (this.observableMedia.isActive('md')) {
    // this.isLockedOpen = true;
    // this.matSidenav.mode = 'side';
    // this.matSidenav.toggle(true);
    // } else {
    this.isLockedOpen = false;
    this.matSidenav.mode = 'over';
    this.matSidenav.toggle(false);
    // }

    // this.matchMediaSubscription = this.nkMatchMedia.onMediaChange.subscribe(
    //   () => {
    //     if (this.observableMedia.isActive('md')) {
    //       this.isLockedOpen = true;
    //       this.matSidenav.mode = 'side';
    //       this.matSidenav.toggle(true);
    //     } else {
    //       this.isLockedOpen = false;
    //       this.matSidenav.mode = 'over';
    //       this.matSidenav.toggle(false);
    //     }
    //   }
    // );
  }

  ngOnDestroy() {
    // this.matchMediaSubscription.unsubscribe();
  }
}

@Directive({ selector: '[nkMatSidenavToggler]' })
export class NkMatSidenavTogglerDirective {
  @Input('nkMatSidenavToggler') id!: string;

  constructor(
    private readonly nkMatSidenavService: NkMatSidenavHelperService
  ) {}

  @HostListener('click')
  onClick() {
    this.nkMatSidenavService.getSidenav(this.id).toggle();
  }
}
