import { Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainPanelActions } from '../../../../actions/app/main-panel.actions';
import { NkSidebarService } from '../../../../components/sidebar/sidebar.service';
import { UserProfileComponent } from '../../../../modals/user-profile/user-profile.component';
import { AuthService } from '../../../../pages/auth/auth.service';
import { SnackBarService } from '../../../../services/app-services/snack-bar.service';
import {
  reload,
  reloadName,
  UsersService,
} from '../../../../services/users.service';
import { ToggleNavigationOpen } from '../../../../stores/app/navbar.state';
import { navigation } from '../../navigation/navigation';

@Component({
  selector: 'nk-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class NkToolbarComponent implements OnDestroy {
  public userStatusOptions: any[];
  public languages: any;
  public selectedLanguage: any;
  public showLoadingBar: boolean;
  public horizontalNav: boolean;
  public noNav: boolean;
  public navigation: any;
  public data: any;
  public userData: any;

  public dialogRef: MatDialogRef<NkToolbarComponent>;
  // public onConfigChanged: Subscription;
  public routerEventsSubscription: Subscription;
  constructor(
    // public loginService: LoginService
    // public userGlobal: UserGlobalService,
    // private readonly nkConfig: NkConfigService,
    private readonly router: Router,
    private readonly sidebarService: NkSidebarService,
    private readonly translate: TranslateService,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly dialogService: MatDialog,
    private readonly usersService: UsersService,
    private readonly snackService: SnackBarService
  ) {
    this.getSingleUserPic();
    reload.subscribe((v) => {
      this.userData.pic = Object.values(v);
      console.log(Object.values(v));
    });
    reloadName.subscribe((v) => {
      this.userData.name = Object.values(v);
      console.log(Object.values(v));
    });
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      { title: 'Away', icon: 'icon-clock', color: '#FFC107' },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      { id: 'en', title: 'English', flag: 'us' },
      { id: 'tr', title: 'Turkish', flag: 'tr' },
    ];

    this.selectedLanguage = this.languages[0];

    this.routerEventsSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.showLoadingBar = true;
      }
      if (event instanceof NavigationEnd) {
        this.showLoadingBar = false;
      }
    });

    /*     this.onConfigChanged = this.nkConfig.onConfigChanged.subscribe(
      (settings) => {
        console.log('Toolbar Changed', settings);
        this.horizontalNav = settings.layout.navigation === 'top';
        this.noNav = settings.layout.navigation === 'none';
      }
    ); */
    this.navigation = navigation;
  }

  public logout() {
    this.authService
      .logout()
      .pipe(catchError(() => of(0)))
      .subscribe(() => window.location.reload());
  }

  public profile() {
    this.getSingleUser();
    // console.log(this.data);
    // const dialogRef = this.dialogService.open(UserProfileComponent, {
    //   data: {
    //     profileData: this.data,
    //   },
    //   width: '400px',
    // });
  }

  viewUserProfile() {
    this.router.navigate(['profile']);
  }

  private async getSingleUser() {
    await this.usersService.getSingleUserProfile().subscribe(
      (data: any) => {
        const result = { data };
        // this.loader.hide();

        this.data = result;
        console.log(this.data);
        const dialogRef = this.dialogService.open(UserProfileComponent, {
          data: {
            profileData: this.data,
          },
          width: '400px',
        });
      },
      (error) => {
        this.snackService.error('Something went wrong');
      }
    );
  }
  private getSingleUserPic() {
    this.usersService.getSingleUserProfile().subscribe(
      (data: any) => {
        const result = { data };
        // this.loader.hide();

        this.data = result;
        this.userData = this.data.data;
        console.log(this.userData.pic);
        // const dialogRef = this.dialogService.open(UserProfileComponent, {
        //   data: {
        //     profileData: this.data,
        //   },
        //   width: '400px',
        // });
      },
      (error) => {
        //  this.loader.hide();
        this.snackService.error('Something went wrong');
      }
    );
  }
  toggleSidebarOpened(key) {
    this.store.dispatch(new ToggleNavigationOpen());
    // this.sidebarService.getSidebar(key).toggleOpen();
  }

  search(value) {
    // Do your search here...
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translate.use(lang.id);
  }

  adminProfile(): void {
    // const dialogRef = this.dialog.open(AdminProfileComponent, {
    //   panelClass: 'dialog',
    //   width: '600px',
    //   data: { article: 'article' },
    // });
    // dialogRef.afterClosed().subscribe((data) => {
    //   // console.log(data);
    //   if (data) {
    //     // this.submitForm();
    //   }
    // });
  }

  ngOnDestroy() {
    // this.onConfigChanged?.unsubscribe();
    this.routerEventsSubscription?.unsubscribe();
  }

  openThemePanel() {
    this.store.dispatch(new MainPanelActions.ThemeOptionPanelOpen(true));
  }
}
