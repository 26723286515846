export enum ModulesNames {
  // CATS = 'CATS',
  // CLAIMS = 'CLAIMS',
  // COMMENTS = 'COMMENTS',
  // DOCUMENTS = 'DOCUMENTS',
  // EVENTS = 'EVENTS',
  // GROUPS = 'GROUPS',
  // GROUPS_HAS_USERS = 'GROUPS_HAS_USERS',
  // INFOS = 'INFOS',
  // ITEMS_CATS = 'ITEMS_CATS',
  // ITEMS_GROUPS = 'ITEMS_GROUPS',
  // MESSENGER = 'MESSENGER',
  // NEWS = 'NEWS',
  // PROMOS = 'PROMOS',
  // REPORTS = 'REPORTS',
  // SLIDE = 'SLIDE',
  ACTIVITY = 'ACTIVITY',
  COMMENTS = 'COMMENTS',
  DOMAINS = 'DOMAINS',
  EMAIL_VERIFY = 'EMAIL_VERIFY',
  FILE = 'FILE',
  ITEMS_REACT = 'ITEMS_REACT',
  MATCHES = 'MATCHES',
  PERMISSIONS = 'PERMISSIONS',
  POST_COMMENTS = 'POST_COMMENTS',
  POST_HAS_DOMAINS = 'POST_HAS_DOMAINS',
  POST_HAS_TAGS = 'POST_HAS_TAGS',
  POST_TAGS = 'POST_TAGS',
  POSTS = 'POSTS',
  PROFILE = 'PROFILE',
  PROFILE_HAS_DOMAIN = 'PROFILE_HAS_DOMAIN',
  REPORTS = 'REPORTS',
  REVIEW = 'REVIEW',
  ROLE_HAS_PERMISSION = 'ROLE_HAS_PERMISSION',
  ROLES = 'ROLES',
  TOTAL_REACT = 'TOTAL_REACT',
  USER_HAS_PERMISSIONS = 'USER_HAS_PERMISSIONS',
  USER_ROLE = 'USER_ROLE',
  USERS = 'USERS',
  SETTING = 'SETTING',
  USER_HAS_BLOCK = 'USER_HAS_BLOCK',
}
