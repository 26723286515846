import * as moment from 'moment';
import { interval, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as T from './time.constant';

export const serverDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const dateToServerFormat = (date: string) =>
  date && moment(date).format(serverDateFormat);

export const fromNowByUTC = (date: string) => moment.utc(date).fromNow();

export const fromNow$ = (date: string) => {
  const utcDate = moment.utc(date);
  const diff = moment().diff(utcDate);
  let o$: Observable<number>;
  if (diff < T.HOUR) {
    o$ = interval(T.MINUTE);
  } else if (diff < T.DAY) {
    o$ = interval(T.HOUR);
  } else {
    return of(moment().calendar(utcDate));
  }
  return o$.pipe(
    startWith(utcDate.fromNow()),
    map(() => utcDate.fromNow())
  );
};
