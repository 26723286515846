import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ToggleNavigationOpen } from '../../../../stores/app/navbar.state';
import { SideMenuStore } from '../../../../stores/app/side-menu.state';
import { NavigationService } from './navbar.service';

@Component({
  selector: 'nk-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NkNavbarComponent implements OnInit, OnDestroy {
  @Input() layout = 'vertical';

  @Select(SideMenuStore.navMenu)
  public navMenu$: Observable<any>;

  public navigation: any;

  private onNavigationChangedSubscription: Subscription;

  constructor(
    private readonly navService: NavigationService,
    private readonly store: Store
  ) {}

  public ngOnInit() {
    // this.onNavigationChangedSubscription = this.navService.onNavigationChanged.subscribe(
    //   (NavMenu) => {
    //     this.navigation = NavMenu.filter((v) => v.children.length > 0);
    //   }
    // );
  }

  public ngOnDestroy() {
    this.onNavigationChangedSubscription?.unsubscribe();
  }

  public toggleSidebarOpened() {
    this.store.dispatch(new ToggleNavigationOpen());
  }
}
