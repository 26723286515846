import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NkNavigationService {
  flatNavigation: any[] = [];

  onItemCollapsed: Subject<any> = new Subject();
  onItemCollapseToggled: Subject<any> = new Subject();

  constructor() {}

  getFlatNavigation(navigation) {
    const flatNavigation = [];
    for (const navItem of navigation) {
      if (navItem.type === 'item' && navItem.url) {
        flatNavigation.push({ ...navItem });
        continue;
      }

      if (navItem.type === 'collapse' || navItem.type === 'group') {
        if (navItem.children) {
          flatNavigation.push(...this.getFlatNavigation(navItem.children));
        }
      }
    }

    return flatNavigation;
  }
}
