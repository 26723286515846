export namespace MainPanelActions {
  export const MAIN_PANEL = '[MAIN_PANEL]';

  // MAIN_PANEL
  // -=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=-

  export class ThemeOptionPanelOpen {
    static readonly type = MAIN_PANEL + ' ThemeOptionPanelOpen';
    constructor(public open: boolean) {}
  }
}
