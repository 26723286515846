<ng-container *ngIf="layout == 'vertical'">
  <div class="navbar-vertical">
    <div class="navbar-header">
      <mat-icon
        class="w80p h70p primary-fg"
        style="height: auto;"
        [svgIcon]="'assets/images/logos/GoSME.svg' | svgIconUrl"
      ></mat-icon>
      <button
        mat-icon-button
        class="toggle-button-navbar"
        (click)="toggleSidebarOpened()"
      >
        <mat-icon fxHide.lt-lg>menu</mat-icon>
        <mat-icon fxHide.gt-md>arrow_back</mat-icon>
      </button>
    </div>
    <div
      class="navbar-content"
      style="overflow-y: auto;"
    >
      <nk-navigation
        [navigation]=" navMenu$ | async "
        layout="vertical"
      ></nk-navigation>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="layout == 'horizontal'">
  <div class="navbar-horizontal">
    <nk-navigation
      [navigation]="navMenu$ | async"
      layout="horizontal"
    ></nk-navigation>
  </div>
</ng-container>
