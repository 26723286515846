import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { QueryBuilderModule } from 'angular2-query-builder';
import { ClipboardModule } from 'ngx-clipboard';
import { FacebookModule } from 'ngx-facebook';
import { MarkdownModule } from 'ngx-markdown';
import { of } from 'rxjs';
import { environment } from '../environments/environment.prod';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { JDataInterceptor } from './interceptors/j-data.interceptor';
import { BlankLayoutComponent } from './layouts/blank/blank-layout.component';
import { NkMainModule } from './layouts/panel/main/main.module';
import { MultipleFileComponent } from './modals/multiple-file/multiple-file.component';
import { ThemesService } from './services/app-services/themes.service';
import { GlobalService } from './services/globalService.service';
import { ReactionService } from './services/reaction.service';
import { AppAbility } from './services/user-global.service';
import { UsersService } from './services/users.service';
import { MaterialModule } from './shared/material.module';
import { GLOBAL_PERSISTENCE_STORES, GLOBAL_STORES } from './stores/stores';

// const a=(type, instance) => {
//   instance[Symbol.for('instanceType')] = type
//   return instance
// }

// const subjectName = (subject)  =>{
//    if (!subject || typeof subject === 'string') {
//       return subject
//    }

//    return subject[Symbol.for('instanceType')]
// }

// const ability = new Ability([], { subjectName })

@NgModule({
  declarations: [
    AppComponent,
    BlankLayoutComponent,
    //EmailTokenComponent,
    MultipleFileComponent,

    // CommentsComponent,
  ],
  imports: [
    AbilityModule,
    AngularEditorModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClipboardModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    MatButtonModule,
    MatCardModule,
    MaterialModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    NkMainModule,
    QueryBuilderModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
    MatPasswordStrengthModule.forRoot(),
    FacebookModule.forRoot(),
    NgxsModule.forRoot(GLOBAL_STORES, {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsStoragePluginModule.forRoot({ key: GLOBAL_PERSISTENCE_STORES }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => of({}),
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(AppRoutes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JDataInterceptor, multi: true },
    { provide: AppAbility, useValue: new AppAbility() },
    { provide: PureAbility, useExisting: AppAbility },
    GlobalService,
    ReactionService,
    ThemesService,
    UsersService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
/* "no-console": [
  true,
  "debug",
  "info",
  "time",
  "timeEnd",
  "trace"
], */
