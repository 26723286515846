export namespace HASH {
  export const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const chr = str.charCodeAt(i);
      // tslint:disable-next-line: no-bitwise
      hash = (hash << 5) - hash + chr;
      // tslint:disable-next-line: no-bitwise
      hash |= 0;
    }
    return hash;
  };
}
