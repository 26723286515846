import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { last, tap } from 'rxjs/operators';
import { MultipleFileService } from '../../services/multiple-file.service';

@Component({
  selector: 'nk-multiple-file',
  templateUrl: './multiple-file.component.html',
  styleUrls: ['./multiple-file.component.scss'],
})
export class MultipleFileComponent implements OnInit {
  public readonly message = '';
  public readonly uploadQues = this.multipleFileService.uploadQues;

  constructor(
    private readonly multipleFileService: MultipleFileService,
    private readonly dialogRef: MatDialogRef<MultipleFileComponent>
  ) {}

  async ngOnInit() {
    while (true) {
      if (!this.uploadQues.length) {
        this.dialogRef.close();
        return;
      }

      await this.upload(this.uploadQues[0]).toPromise();
      this.uploadQues.shift();
    }
  }

  /*  testUpload(item: { url: string; file: File; upload: number }) {
    return interval(100).pipe(
      take(100),
      tap((i) => (item.upload = Math.round((100 * (i + 1)) / 100))),
      last()
    );
  } */

  upload(item: {
    url: string;
    file: File;
    upload: number;
    callback?: () => {};
  }) {
    return this.multipleFileService.upload(item.url, item.file).pipe(
      tap((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          item.upload = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          item?.callback();
          // this.fileInfos = this.multipleFileService.getFiles();
        }
      }),
      last()
    );
  }
}
