import { Component } from '@angular/core';

@Component({
  selector: 'nk-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class NkFooterComponent {
  constructor() {}
  public year: number = new Date().getFullYear();
}
