import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NkDirectivesModule } from '../directives/directives';
import { NkPipesModule } from '../pipes/pipes.module';

const modules = [
  CommonModule,
  FlexLayoutModule,
  FormsModule,
  NkDirectivesModule,
  NkPipesModule,
  ReactiveFormsModule,
];
@NgModule({ imports: modules, exports: modules })
export class NkSharedModule {}
