import { Pipe, PipeTransform } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HASH } from '../shared/common-packages/hash';

const IconRegister = {} as any;
(window as any).IconRegister = IconRegister;
@Pipe({ name: 'svgIconUrl' })
export class SvgIconUrlPipe implements PipeTransform {
  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer
  ) {}
  transform(url: string, args: any[] = []) {
    const xyz = IconRegister[url];
    if (xyz) return xyz;
    const hash = HASH.hashCode(url).toString();
    IconRegister[url] = hash;
    this.iconRegistry.addSvgIcon(
      hash,
      this.sanitizer.bypassSecurityTrustResourceUrl(url)
    );
    return hash;
  }
}
