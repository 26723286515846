import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'session-expire',
  templateUrl: './session-expire.component.html',
})
export class SessionExpireComponent {
  public user;
  constructor(
    public dialogRef: MatDialogRef<SessionExpireComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.user = _data.user;
    // this.user$ = _data.user.pipe(
    //   catchError(e => {
    //     this.error = true;
    //     return throwError(e);
    //   })
    // );
  }

  public submit(val) {
    this.dialogRef.close(val);
  }
}
