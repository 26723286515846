<mat-toolbar class="p0 mat-elevation-z1">
  <!-- <mat-progress-bar
    *ngIf="showLoadingBar"
    class="loading-bar"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar> -->
  <div
    fxFlex
    fxFill
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div
      fxFlex="1 0 auto"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button
        mat-icon-button
        class="toggle-button-navbar"
        *ngIf="!noNav"
        (click)="toggleSidebarOpened('navbar')"
        fxHide.gt-md
      >
        <mat-icon>menu</mat-icon>
      </button>
      <div
        class="toolbar-separator"
        fxHide.gt-md
      ></div>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="horizontalNav"
      >
        <div class="logo ml16">
          <img
            class="logo-icon"
            [src]="userData.pic ? userData.pic :'https://www.flaticon.com/svg/static/icons/svg/149/149071.svg'"
          >
        </div>
      </div>
      <div class="px8 px-mat-16">
        <nk-shortcuts [navigation]="navigation"></nk-shortcuts>
      </div>
    </div>
    <div fxLayout="row">
      <button
        mat-button
        [matMenuTriggerFor]="userMenu"
        class="user-button"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <img
            class="avatar m8"
            [src]="userData?.pic ? userData.pic :'https://www.flaticon.com/svg/static/icons/svg/149/149071.svg'"
          >
          <span
            class="username mr12"
            fxShow.gt-sm
          >{{userData?.name}}</span>
          <mat-icon
            class="s16"
            fxHide.xs
          >keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <mat-menu
        #userMenu="matMenu"
        [overlapTrigger]="false"
      >
        <button
          mat-menu-item
          (click)='profile()'
        >
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button>
        <!-- <button mat-menu-item>
          <mat-icon>account_circle</mat-icon>
          <span>My Profile</span>
        </button> -->
        <!-- <button
          mat-menu-item
          class=""
        >
          <mat-icon>mail</mat-icon>
          <span>Inbox</span>
        </button> -->
        <button
          mat-menu-item
          class=""
          (click)='logout()'
        >
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
      <div class="toolbar-separator"></div>
      <button
        mat-button
        (click)="openThemePanel()"
      >
        <mat-icon>settings</mat-icon>
      </button>
      <div class="toolbar-separator"></div>
      <!--
      <div class="toolbar-separator"></div>
      <nk-search-bar (onInput)="search($event)"></nk-search-bar>
      <div class="toolbar-separator"></div>
      <button mat-button
        fxHide
        fxShow.gt-xs
        class="language-button"
        [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr8" [src]="'assets/images/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

      <mat-menu #languageMenu="matMenu"
        [overlapTrigger]="false">

        <button mat-menu-item
          *ngFor="let lang of languages"
          (click)="setLanguage(lang)">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </div>
                </button>

      </mat-menu>
      <div
        class="toolbar-separator"
        fxHide
        fxShow.gt-xs
      ></div>-->
      <button
        mat-button
        nkMatSidenavToggler="quick-panel"
        aria-label="Toggle quick panel"
      >
        <mat-icon class="icon">format_list_bulleted</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
