import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserGlobalService } from 'apps/cwd/src/app/services/user-global.service';
import { UsersService } from 'apps/cwd/src/app/services/users.service';
import { of } from 'rxjs';

@Component({
  selector: 'nk-quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NkQuickPanelComponent implements OnInit {
  public activityData: any;
  public user;
  public data = [
    {
      user: { username: 'Alice Freema', name: 'Alice Freeman', id: 1 },
      content: {
        title: 'Alice Freeman started following you.',
        time: '13 mins. ago',
        type: 'favorite',
      },
    },
    {
      user: { username: 'Andrew Green', name: 'Andrew Green', id: 2 },
      content: {
        title: 'Andrew Green sent you a message.',
        time: 'June 10,2018',
        type: 'message',
      },
    },
    {
      user: { username: 'Vincent Munoz', name: 'Vincent Munoz', id: 3 },
      content: {
        title: 'Vincent Munoz shared a photo with you.',
        time: 'June 9,2018',
        type: 'reviews',
      },
    },
    {
      user: { username: 'Carl Henderson', name: 'Carl Henderson', id: 4 },
      content: {
        title: 'Garry Newman shared a public post with your group.',
        time: 'June 7,2018',
        type: 'comment',
      },
    },
  ];
  public date: Date;
  public settings: any;

  public activityList: any[];
  public eventsListCount: any;

  constructor(
    private readonly userGlobalService: UserGlobalService,
    private readonly usersService: UsersService
  ) {
    this.date = new Date();
    this.settings = {
      notify: true,
      cloud: false,
      retro: true,
    };
  }

  ngOnInit() {
    this.user = this.userGlobalService.user;
    this.myActivity();
  }

  public myActivity() {
    this.usersService
      .getActivity(this.user?.id, {
        filter: {
          condition: 'AND',
          rules: [],
          valid: true,
        },
        config: {
          sort: 'id',
          order: 'ASC',
          page: 1,
          limit: 50,
        },
      })
      .subscribe((v: any) => {
        this.activityData = v.data;
        console.log(this.activityData, 'get this.activityData');
      });
  }
}
