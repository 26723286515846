import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NkNavHorizontalCollapseComponent } from './horizontal/nav-collapse/nav-horizontal-collapse.component';
import { NkNavHorizontalItemComponent } from './horizontal/nav-item/nav-horizontal-item.component';
import { NkNavigationComponent } from './navigation.component';
import { NkNavVerticalCollapseComponent } from './vertical/nav-collapse/nav-vertical-collapse.component';
import { NkNavVerticalGroupComponent } from './vertical/nav-group/nav-vertical-group.component';
import { NkNavVerticalItemComponent } from './vertical/nav-item/nav-vertical-item.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  exports: [NkNavigationComponent],
  declarations: [
    NkNavHorizontalCollapseComponent,
    NkNavHorizontalItemComponent,
    NkNavigationComponent,
    NkNavVerticalCollapseComponent,
    NkNavVerticalGroupComponent,
    NkNavVerticalItemComponent,
  ],
})
export class NkNavigationModule {}
