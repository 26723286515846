import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatColors } from '../../shared/mat-colors';

enum ViewType {
  PALETTES = 'palettes',
  HUES = 'hues',
}

@Component({
  selector: 'nk-material-color-picker',
  templateUrl: './material-color-picker.component.html',
  styleUrls: ['./material-color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NkMaterialColorPickerComponent {
  colors: any;
  selectedColor: any;
  // hues: string[];
  view = ViewType.PALETTES;

  public selectedPalette = '';
  public selectedHue = '';
  public selectedFg = '';
  @Input() value: any;
  @Output() valueChange = new EventEmitter();
  // @Output() selectedPaletteChange = new EventEmitter();
  // @Output() selectedHueChange = new EventEmitter();
  @Output() selectedClassChange = new EventEmitter();
  // @Output() selectedBgChange = new EventEmitter();
  // @Output() selectedFgChange = new EventEmitter();

  _selectedClass = 'red';
  @Input()
  set selectedClass(value) {
    if (this._selectedClass !== value) {
      const match = (value || '')
        .toString()
        .match(/^mat-([-\D]+)(?:-(A?\d+))?-bg$/);
      if (match) {
        const [palette, hue = '500'] = match.slice(1);

        if (Object.keys(this.colors).includes(palette)) {
          this.selectedPalette = palette;
        } else {
          this.selectedPalette = 'red';
        }
        if (Object.keys(this.colors[this.selectedPalette]).includes(hue)) {
          this.selectedHue = hue;
        } else {
          this.selectedHue = '500';
        }
        this._selectedClass = `mat-${this.selectedPalette}-${this.selectedHue}-bg`;
      } else {
        this.selectedPalette = 'red';
        this.selectedHue = '500';
        this._selectedClass = `mat-${this.selectedPalette}-${this.selectedHue}-bg`;
      }
    }
  }

  get selectedClass(): string {
    return this._selectedClass;
  }

  _selectedBg = 'red';
  @Input()
  set selectedBg(value) {
    if (value && value !== '' && this._selectedBg !== value) {
      for (const palette of Object.keys(this.colors)) {
        for (const hue of Object.keys(this.colors[palette])) {
          if (this.colors[palette][hue][0] === value) {
            this.selectedPalette = palette;
            this.selectedHue = hue;
            this._selectedBg = value;
            return;
          }
        }
      }
    }

    this.selectedPalette = 'red';
    this.selectedHue = '500';
    this._selectedClass = `mat-${this.selectPalette}-${this.selectedHue}-bg`;
    this._selectedBg = 'red';
  }

  get selectedBg(): string {
    return this._selectedBg;
  }

  constructor() {
    this.colors = MatColors.getAll();
    /*     this.hues = [
      '50',
      '100',
      '200',
      '300',
      '400',
      '500',
      '600',
      '700',
      '800',
      '900',
      'A100',
      'A200',
      'A400',
      'A700',
    ]; */
  }

  selectPalette(palette) {
    this.selectedPalette = palette;
    this.selectedHue = '';
    // this.updateSelectedColor();
    this.view = ViewType.HUES;
  }

  selectHue(hue) {
    this.selectedHue = hue;
    this.updateSelectedColor();
  }

  removeColor() {
    this.selectedPalette = '';
    this.selectedHue = '';
    // this.updateSelectedColor();
    this.view = ViewType.PALETTES;
  }

  updateSelectedColor() {
    setTimeout(() => {
      if (
        this.selectedColor &&
        this.selectedPalette === this.selectedColor.palette &&
        this.selectedHue === this.selectedColor.hue
      ) {
        return;
      }

      if (this.selectedPalette !== '' && this.selectedHue !== '') {
        const color = this.colors[this.selectedPalette][this.selectedHue];
        [this._selectedBg, this.selectedFg] = color;
        this._selectedClass =
          'mat-' + this.selectedPalette + '-' + this.selectedHue + '-bg';
      } else {
        this._selectedBg = '';
        this.selectedFg = '';
      }

      this.selectedColor = {
        palette: this.selectedPalette,
        hue: this.selectedHue,
        class: this.selectedClass,
        bg: this.selectedBg,
        fg: this.selectedFg,
      };

      // this.selectedPaletteChange.emit(this.selectedPalette);
      // this.selectedHueChange.emit(this.selectedHue);
      this.selectedClassChange.emit(this.selectedClass);
      // this.selectedBgChange.emit(this.selectedBg);
      // this.selectedFgChange.emit(this.selectedFg);

      this.value = this.selectedColor;
      this.valueChange.emit(this.selectedColor);
    });
  }

  backToPaletteSelection() {
    this.view = ViewType.PALETTES;
  }

  async onMenuOpen() {
    if (this.selectedPalette === '') {
      this.view = ViewType.PALETTES;
    } else {
      this.view = ViewType.HUES;
    }
  }

  getHueFromPalette(palette) {
    return Object.keys(this.colors[palette]).sort((a, b) => +a - +b);
  }
}
