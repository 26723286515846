<ng-container *ngIf="!item.hidden">
  <div class="group-title">
    <span
      class="hint-text"
      [translate]="item.translate"
    >{{ item.title }}</span>
  </div>
  <div class="group-items">
    <ng-container *ngFor="let item of item.children">
      <nk-nav-vertical-group
        *ngIf="item.type=='group'"
        [item]="item"
      ></nk-nav-vertical-group>
      <nk-nav-vertical-collapse
        *ngIf="item.type=='collapse'"
        [item]="item"
      ></nk-nav-vertical-collapse>
      <nk-nav-vertical-item
        *ngIf="item.type=='item'"
        [item]="item"
      ></nk-nav-vertical-item>
    </ng-container>
  </div>
</ng-container>
