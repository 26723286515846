<!-- <button
  #openButton
  mat-icon-button
  class="open-button mat-primary-bg mat-elevation-z2"
  (click)="openBar()"
>
  <mat-icon>settings</mat-icon>
</button> -->
<div
  class="theme-options-panel-overlay"
  #overlay
  (click)="closeBar()"
  [fxHide]="barClosed"
  [@fadeInOut]="!barClosed"
></div>
<div
  #panel
  class="theme-options-panel mat-card mat-elevation-z8"
>
  <button
    mat-icon-button
    class="close-button"
    (click)="closeBar()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div
    class="theme-options-panel-inner"
    fxLayout="column"
  >
    <h3>Themes:</h3>
    <mat-radio-group
      (change)="updateLayoutTheme($event.value)"
      [value]="(appThemeConfig$ | async).layout.theme"
      fxLayout="row"
    >
      <mat-radio-button
        *ngFor="let item of (themes | entries)"
        class="mxa"
        [value]="item[0]"
      >{{item[1].name}}</mat-radio-button>
    </mat-radio-group>
    <h3 class="mt24">Font Size:</h3>
    <mat-slider
      class="w100p"
      [max]="100"
      [min]="0"
      [thumbLabel]="false"
    >
    </mat-slider>
    <mat-divider></mat-divider>
    <h3 class="mt24">Navigation:</h3>
    <mat-radio-group
      (change)="updateLayoutNavigation($event.value)"
      [value]="(navLayoutConfig$ | async).expected"
      fxLayout="row"
    >
      <mat-radio-button
        class="mxa"
        value="top"
      >Top</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="left"
      >Left</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="right"
      >Right</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="none"
      >None</mat-radio-button>
    </mat-radio-group>
    <h3 class="mt24">Toolbar:</h3>
    <mat-radio-group
      (change)="updateLayoutToolbar($event.value)"
      [value]="(appThemeConfig$ | async).layout.toolbar"
      fxLayout="row"
    >
      <mat-radio-button
        class="mxa"
        value="below"
      >Below</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="above"
      >Above</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="none"
      >None</mat-radio-button>
    </mat-radio-group>
    <h3 class="mt24">Footer:</h3>
    <mat-radio-group
      (change)="updateLayoutFooter($event.value)"
      [value]="(appThemeConfig$ | async).layout.footer"
      fxLayout="row"
    >
      <mat-radio-button
        class="mxa"
        value="below"
      >Below</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="above"
      >Above</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="none"
      >None</mat-radio-button>
    </mat-radio-group>
    <mat-divider></mat-divider>
    <h3 class="mt24">Layout Mode:</h3>
    <mat-radio-group
      (change)="updateLayoutMode($event.value)"
      [value]="(appThemeConfig$ | async).layout.mode"
      fxLayout="row"
    >
      <mat-radio-button
        class="mxa"
        value="boxed"
      >Boxed</mat-radio-button>
      <mat-radio-button
        class="mxa"
        value="fullwidth"
      >Fullwidth</mat-radio-button>
    </mat-radio-group>
    <mat-divider></mat-divider>
    <h3>Colors:</h3>
    <div
      class="colors"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div>Toolbar Color</div>
        <nk-material-color-picker
          (selectedClassChange)="updateColorClassesToolbar($event)"
          [selectedClass]="(appThemeConfig$ | async).colorClasses.toolbar"
        ></nk-material-color-picker>
      </div>
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div>Navigation Bar Color</div>
        <nk-material-color-picker
          (selectedClassChange)="updateColorClassesNavbar($event)"
          [selectedClass]="(appThemeConfig$ | async).colorClasses.navbar"
        ></nk-material-color-picker>
      </div>
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div>Footer Color</div>
        <nk-material-color-picker
          (selectedClassChange)="updateColorClassesFooter($event)"
          [selectedClass]="(appThemeConfig$ | async).colorClasses.footer"
        ></nk-material-color-picker>
      </div>
    </div>
    <mat-divider></mat-divider>
    <button
      mat-raised-button
      color="warn"
      (click)="reset()"
    >Reset All</button>
    <!-- <mat-divider></mat-divider>
    <h3>Router Animation:</h3>
    <mat-form-field class="w100p">
      <mat-select
        class="p0"
        [(ngModel)]="config.routerAnimation"
      >
        <mat-option value="none">
          None
        </mat-option>
        <mat-option value="slideUp">
          Slide up
        </mat-option>
        <mat-option value="slideDown">
          Slide down
        </mat-option>
        <mat-option value="slideRight">
          Slide right
        </mat-option>
        <mat-option value="slideLeft">
          Slide left
        </mat-option>
        <mat-option value="fadeIn">
          Fade in
        </mat-option>
      </mat-select>
    </mat-form-field> -->
  </div>
</div>
