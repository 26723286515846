import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NkDirectivesModule } from '../../../../directives/directives';
import { NkPipesModule } from '../../../../pipes/pipes.module';
import { NkContentComponent } from './content.component';

@NgModule({
  declarations: [NkContentComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    NkDirectivesModule,
    NkPipesModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [NkContentComponent],
})
export class NkContentModule {}
