import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Routes } from '../configs/api.router.config';

@Injectable()
export class GlobalService {
  constructor(private readonly http: HttpClient) {}

  public getGroup() {
    return this.http.get(Routes.getGroup, {});
  }
  public getDomains() {
    return this.http.get(Routes.getDomains, {});
  }

  public group(id) {
    return this.http.post(Routes.groupMessage({ id }), {});
  }
  public cat(id) {
    return this.http.post(Routes.catMessage({ id }), {});
  }

  public groupNews(id) {
    return this.http.post(Routes.groupNews({ id }), {});
  }
  public catNews(id) {
    return this.http.post(Routes.catNews({ id }), {});
  }
  public groupPromo(id) {
    return this.http.post(Routes.groupPromo({ id }), {});
  }
  public catSlide(id) {
    return this.http.post(Routes.catSlide({ id }), {});
  }
  public groupSlide(id) {
    return this.http.post(Routes.groupSlide({ id }), {});
  }
  public catPromo(id) {
    return this.http.post(Routes.catPromo({ id }), {});
  }
  public groupEvent(id) {
    console.log(id);
    return this.http.post(Routes.groupEvent({ id }), {});
  }
  public groupDocument(id) {
    console.log(id);
    return this.http.post(Routes.groupDocument({ id }), {});
  }
  public catEvent(id) {
    console.log(id);
    return this.http.post(Routes.catEvent({ id }), {});
  }

  private getUserRole() {}
}
