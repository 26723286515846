import { AllThemes } from '../../configs/theme.config';
import { Color } from '../../shared/common-packages/color';
import { MatColors } from '../../shared/mat-colors';

const minified = ([k, v]: [string, [string, string][]]) =>
  `${k}{${v.map(([x, y]) => `${x}:${y}`).join(';')}}`;

const expanded = ([k, v]: [string, [string, string][]]) =>
  `${k}{\n${v.map(([x, y]) => `  ${x}: ${y};\n`).join('')}}\n`;

const HeightWidthHelpers = () =>
  Object.entries({ height: 'h', width: 'w' }).flatMap(([prop, abbrev]) => {
    const a: [string, [string, string][]][] = Array(101)
      .fill(0)
      .flatMap((_, index) => {
        const size = index * 4;
        const length = `${size}px`;

        return [
          [`.${abbrev}${size}`, [[prop, `${length}!important`]]],
          [`.${abbrev}${size}n`, [[`min-${prop}`, `${length}!important`]]],
          [`.${abbrev}${size}x`, [[`max-${prop}`, `${length}!important`]]],
        ];
      });
    const b: [string, [string, string][]][] = Array(21)
      .fill(0)
      .map((_, i) => {
        const iP = 5 * i;
        const sizeP = 5 * i + '%';

        return [`.${abbrev}${iP}p`, [[`${prop}`, `${sizeP}!important`]]];
      });
    return [...a, ...b];
  });

const SquareHelpers = () =>
  Array(101)
    .fill(0)
    .map((_, index) => {
      const size = index * 4;
      const length = `${size}px`;

      return [
        `.s${size}`,
        [
          ['height', `${length}!important`],
          ['width', `${length}!important`],
        ],
      ];
    }) as [string, [string, string][]][];

const MarginPaddingHelpers = () =>
  Object.entries({ margin: 'm', padding: 'p' }).flatMap(([prop, abbrev]) => {
    const a: [string, [string, string][]][] = Array(65)
      .fill(0)
      .flatMap((_, index) => {
        const size = index * 4;
        const length = `${size}px`;

        return [
          [`.${abbrev}${size}`, [[prop, `${length}!important`]]],
          [
            `.${abbrev}x${size}`,
            [
              [`${prop}-right`, `${length}!important`],
              [`${prop}-left`, `${length}!important`],
            ],
          ],

          [
            `.${abbrev}y${size}`,
            [
              [`${prop}-top`, `${length}!important`],
              [`${prop}-bottom`, `${length}!important`],
            ],
          ],
          [`.${abbrev}t${size}`, [[`${prop}-top`, `${length}!important`]]],
          [`.${abbrev}r${size}`, [[`${prop}-right`, `${length}!important`]]],
          [`.${abbrev}b${size}`, [[`${prop}-bottom`, `${length}!important`]]],
          [`.${abbrev}l${size}`, [[`${prop}-left`, `${length}!important`]]],
        ];
      });
    const b: [string, [string, string][]][] = Array(21)
      .fill(0)
      .map((_, i) => {
        const iP = 5 * i;
        const sizeP = 5 * i + '%';

        return [`.${abbrev}${iP}p`, [[`${prop}`, `${sizeP}!important`]]];
      });
    let c: [string, [string, string][]][] = [];
    if (prop === 'margin') {
      c = [
        [`.ma`, [[`margin`, `auto!important`]]],
        [`.mta`, [[`margin-top`, `auto!important`]]],
        [`.mra`, [[`margin-right`, `auto!important`]]],
        [`.mba`, [[`margin-bottom`, `auto!important`]]],
        [`.mla`, [[`margin-left`, `auto!important`]]],
        [
          `.mxa`,
          [
            [`margin-right`, `auto!important`],
            [`margin-left`, `auto!important`],
          ],
        ],
        [
          `.mya`,
          [
            [`margin-top`, `auto!important`],
            [`margin-bottom`, `auto!important`],
          ],
        ],
      ];
    }
    return [...a, ...b, ...c];
  });

const $defaultFontSize = 18;
const relativeFontSize = (px: number) => (px / $defaultFontSize).toFixed(3);

const FontWeightHelpers = () =>
  Array(9)
    .fill(0)
    .map((_, i) => (i + 1) * 100)
    .flatMap((weight) => [
      [`.fw${weight}`, [[`font-weight`, `${weight}!important`]]],
    ]) as [string, [string, string][]][];

const FontSizeHelpers = () =>
  Array(60)
    .fill(0)
    .map((_, i) => (i + 1) * 2)
    .flatMap((size) => {
      return [
        [`.fs${size}`, [[`font-size`, `${size}px!important`]]],
        [
          `.em${size}`,
          [[`font-size`, `${relativeFontSize(size)}em!important`]],
        ],
        [`.lh${size}`, [[`line-height`, `${size}px!important`]]],
      ];
    }) as [string, [string, string][]][];

const HELPERS = [
  HeightWidthHelpers,
  SquareHelpers,
  MarginPaddingHelpers,
  FontWeightHelpers,
  FontSizeHelpers,
].flatMap((v) => v());

const rgba = (x: string, a: number) => Color(x).alpha(a).rgb().string();
const generateColorClasses = (
  colorName: string,
  color: string,
  contrastColor: string,
  hue: string
) => {
  const bgOnly = [
    [`.${colorName}${hue}-bg`, [[`background-color`, `${color}!important`]]],
  ];

  const bgAndFg = [
    [
      `.mat-${colorName}${hue}-bg`,
      [
        [`background-color`, `${color}!important`],
        [`color`, `${contrastColor}!important`],
      ],
    ],
    [
      `.mat-${colorName}${hue}-bg[disabled]`,
      [
        [`background-color`, `${rgba(color, 0.12)}!important`],
        [`color`, `${rgba(contrastColor, 0.26)}!important`],
      ],
    ],
  ];

  const fgOnly = [
    [`.${colorName}${hue}-fg`, [[`color`, `${color}!important`]]],
  ];

  const border = [
    [`.${colorName}${hue}-b`, [[`border-color`, `${color}!important`]]],
  ];

  const borderTop = [
    [`.${colorName}${hue}-bt`, [[`border-top-color`, `${color}!important`]]],
  ];

  const borderRight = [
    [`.${colorName}${hue}-br`, [[`border-right-color`, `${color}!important`]]],
  ];

  const borderBottom = [
    [`.${colorName}${hue}-bb`, [[`border-bottom-color`, `${color}!important`]]],
  ];

  const borderLeft = [
    [`.${colorName}${hue}-bl`, [[`border-left-color`, `${color}!important`]]],
  ];

  return [
    bgOnly,
    bgAndFg,
    fgOnly,
    border,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
  ].flat();
};

const $matColorHues = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  'A100',
  'A200',
  'A400',
  'A700',
];
// const colorGenerator = ($colorsMap: {
//   [key: string]: { [key: string]: [string, string] };
// }) => {
//   return Object.entries($colorsMap).flatMap(([$colorName, $colorMap]) => {
//     return $matColorHues.flatMap(($hue) => {
//       const $color = $colorMap[$hue];
//       const $contrastColor = $colorMap['contrast'][$hue];
//       const css = [];
//       if ($color != null && $contrastColor != null) {
//         css.push(
//           generateColorClasses($colorName, $color, $contrastColor, `-${$hue}`)
//         );

//         // Run the generator one more time for default values (500)
//         if ($hue === '500') {
//           css.push(
//             generateColorClasses($colorName, $color, $contrastColor, '')
//           );
//         }
//       }
//       return css.flat();
//     });
//   });
// };

const colorGenerator = ($colorsMap: {
  [key: string]: { [key: string]: [string, string] };
}) => {
  return Object.entries($colorsMap).flatMap(([$colorName, $colorMap]) => {
    return Object.entries($colorMap).flatMap(
      ([$hue, [$color, $contrastColor]]) => {
        // const $color = $colorMap[$hue];
        // const $contrastColor = $colorMap['contrast'][$hue];
        const css = [];
        // if ($color != null && $contrastColor != null) {
        css.push(
          generateColorClasses($colorName, $color, $contrastColor, `-${$hue}`)
        );

        // Run the generator one more time for default values (500)
        if ($hue === '500') {
          css.push(
            generateColorClasses($colorName, $color, $contrastColor, '')
          );
        }
        // }
        return css.flat();
      }
    );
  });
};

const themeColorGenerator = ($theme: {
  primary: string;
  accent: string;
  warn: string;
}) => {
  const $themeColorsMap = {
    primary: MatColors.getAll()[$theme.primary],
    accent: MatColors.getAll()[$theme.accent],
    warn: MatColors.getAll()[$theme.warn],
  };
  return colorGenerator($themeColorsMap);
};

const ColorEngine = () => {
  const themePalette = Object.entries(AllThemes).flatMap(
    ([themeName, { klass, theme }]) => {
      return themeColorGenerator(theme).map(
        (v) => ((v[0] = (klass ? `.${klass} ` : '') + v[0]), v)
      );
    }
  );
  const allPalette = colorGenerator(MatColors.getAll());
  return [themePalette, allPalette].flat() as [string, [string, string][]][];
};

const ALL_CSS = [...HELPERS, ...ColorEngine()];
let CSSString: string | null = ALL_CSS.map(minified).join('');

const injectStyleSheetInHead = (css: string) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.setAttribute('for', 'helpers');
  head.appendChild(style);
  if ((style as any).styleSheet) {
    // This is required for IE8 and below.
    (style as any).styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
};

export const LoadHelperStyleSheet = () => {
  if (CSSString) {
    injectStyleSheetInHead(CSSString);
    CSSString = null;
  }
};
