import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    const keys: any[] = [];
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        keys.push({ key, value: value[key] });
      }
    }
    return keys;
  }
}

@Pipe({ name: 'entries' })
export class EntriesPipe implements PipeTransform {
  transform(value: any, args: string[]): any {
    return Object.entries(value);
  }
}
