import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import {
  NkNavigationModule,
  NkSearchBarModule,
  NkShortcutsModule,
  NkSidebarModule,
  NkThemeOptionsModule,
} from '../../../components';
import { AppThemeConfig } from '../../../configs/theme.config';
import { NkModule } from '../../../shared/nk.module';
import { NkSharedModule } from '../../../shared/shared.module';
import { NkContentModule } from './content/content.module';
import { NkFooterModule } from './footer/footer.module';
import { NkMainComponent } from './main.component';
import { NkNavbarModule } from './navbar/navbar.module';
import { NkQuickPanelModule } from './quick-panel/quick-panel.module';
import { NkToolbarModule } from './toolbar/toolbar.module';

@NgModule({
  declarations: [NkMainComponent],
  imports: [
    MatSidenavModule,
    NkContentModule,
    NkFooterModule,
    NkModule.forRoot(AppThemeConfig),
    NkNavbarModule,
    NkNavigationModule,
    NkQuickPanelModule,
    NkSearchBarModule,
    NkSharedModule,
    NkShortcutsModule,
    NkSidebarModule,
    NkThemeOptionsModule,
    NkToolbarModule,
    RouterModule,
  ],
  exports: [NkMainComponent],
})
export class NkMainModule {}
