import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from '../../../../shared/common-packages/lodash';
import { navigation } from '../../navigation/navigation';

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {
  navigation: any;
  defaultNavigation: any;
  isSetConfigRan = false;

  onNavigationChanged: BehaviorSubject<any>;

  constructor() {
    // Set the default config from the user provided one (forRoot)
    this.defaultNavigation = [...navigation];

    // Set the config from the default config
    this.navigation = cloneDeep(this.defaultNavigation);
    this.onNavigationChanged = new BehaviorSubject(this.navigation);
  }

  filterNavigationByPerm() {
    this.emitNav();
  }

  resetNavigation() {
    this.navigation = cloneDeep(this.defaultNavigation);
    this.emitNav();
  }

  setNavigation(nav): void {
    this.emitNav();
  }

  addNavigation(nav, path) {
    const isExist = this.navigation[path].children.some(
      (menus) => menus.id === nav.id
    );
    if (!isExist) {
      this.navigation[path].children.push(nav);
    }
    this.emitNav();
  }

  removeNavigation(path, id) {
    const nav = this.navigation[path].children.findIndex(
      (menus) => menus.id === id
    );
    if (nav > -1) {
      this.navigation[path].children.splice(nav, 1);
    }
    this.emitNav();
  }

  emitNav() {
    this.onNavigationChanged.next(this.navigation);
  }

  ngOnDestroy() {}
}
