import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Routes } from '../configs/api.router.config';

export const Reactionable = [
  'COMMENTS',
  'CLAIMS',
  'EVENTS',
  'MESSENGER',
  'NEWS',
  'PROMOS',
  'REPORTS',
  'SLIDE',
  'POSTS',
  'POST_COMMENTS',
];

export enum ReactionableEnum {
  COMMENTS,
  CLAIMS,
  EVENTS,
  MESSENGER,
  NEWS,
  PROMOS,
  REPORTS,
  SLIDE,
  POSTS,
  POST_COMMENTS,
}

export enum ReactionsEnum {
  disLike,
  like,
}

export const ReactionTypes = ['disLike', 'like'];

export interface ReactionQuery {
  userId?: number;
  tableId?: number;
  itemId?: number;
  user?: any;
}

interface ItemsReactQuery {
  userId?: number;
  tableId?: number;
  itemId?: number;
}

interface TotalReactQuery {
  tableId: number;
  itemId: number;
}

@Injectable()
export class ReactionService {
  constructor(private readonly httpClient: HttpClient) {}
  forModule(module: ReactionableEnum) {
    return {
      forItem: (itemId: number) => {
        return this.forItem(module, itemId);
      },
    };
  }

  forItem(module: ReactionableEnum, itemId: number) {
    return {
      react: (reaction: ReactionsEnum, set: boolean) => {
        return this.react(module, itemId, reaction, set);
      },
    };
  }

  react(
    module: ReactionableEnum,
    itemId: number,
    reaction: ReactionsEnum,
    set: boolean
  ) {
    return this.httpClient.post(Routes.React, {
      module: Reactionable[module],
      itemId,
      reaction: ReactionTypes[reaction],
      set,
    });
  }
}
