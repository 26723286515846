<div
  class="nk-search-bar"
  [ngClass]="{'expanded':!collapsed}"
>
  <div
    class="nk-search-bar-content"
    [ngClass]="toolbarColor"
  >
    <label for="nk-search-bar-input">
      <button
        mat-icon-button
        class="nk-search-bar-expander"
        aria-label="Expand Search Bar"
        (click)="expand()"
        *ngIf="collapsed"
      >
        <mat-icon class="s24">search</mat-icon>
      </button>
      <!--<span class="nk-search-bar-loader" fxLayout="row" fxLayoutAlign="center center" *ngIf="!collapsed">
                <mat-progress-spinner color="mat-accent" mode="indeterminate"></mat-progress-spinner>
            </span>-->
    </label>
    <input
      id="nk-search-bar-input"
      class="ml24"
      type="text"
      placeholder="Search"
      (input)="search($event)"
      fxFlex
    >
    <button
      mat-icon-button
      class="nk-search-bar-collapser"
      (click)="collapse()"
      aria-label="Collapse Search Bar"
    >
      <mat-icon class="s24">close</mat-icon>
    </button>
  </div>
</div>
