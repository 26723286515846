import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  take,
} from 'rxjs/operators';
import { AppThemeConfig } from '../../configs/theme.config';
import { NkMatchMediaService } from '../../services/app-services/match-media.service';
import { immerv } from '../../shared/common-packages/immer';
import { merge } from '../../shared/common-packages/lodash';

export enum NavigationType {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  NONE = 'none',
}

const NavigationLayoutSetting = {
  type: AppThemeConfig.layout.navigation as NavigationType, // 'right', 'left', 'top', 'none'
  expected: AppThemeConfig.layout.navigation as NavigationType, // 'right', 'left', 'top', 'none'
  open: true, // true, false,
  large: true, // true, false
};

const MODEL_NAME = 'APP_NAVIGATION_LAYOUT';

const defCon = NavigationLayoutSetting;

export class UpdateNavigationLayoutSetting {
  static readonly type = MODEL_NAME + ' UpdateNavigationLayoutSetting';
  constructor(public payload: any) {}
}

export class ToggleNavigationOpen {
  static readonly type = MODEL_NAME + ' ToggleNavigationOpen';
}

export class UpdateNavigationPosition {
  static readonly type = MODEL_NAME + ' UpdateNavigationPosition';
  constructor(public payload: NavigationType) {}
}

export class ResetNavigationLayout {
  static readonly type = MODEL_NAME + ' ResetNavigationLayout';
  constructor() {}
}

export class NavigationLayoutSettingModel {
  static id = MODEL_NAME;
  navLayout: typeof NavigationLayoutSetting;
}

@State<NavigationLayoutSettingModel>({
  name: MODEL_NAME,
  defaults: { navLayout: defCon },
})
@Injectable()
export class NavigationLayoutSettingStore implements NgxsOnInit {
  constructor(
    private readonly nkMatchMediaService: NkMatchMediaService,
    private readonly router: Router,
    private readonly store: Store
  ) {}

  @Selector()
  public static navLayoutConfig(state: NavigationLayoutSettingModel) {
    return state.navLayout;
  }

  public ngxsOnInit() {
    this.listenForMediaChange();
    this.listenForRouteChange();
  }

  public listenForMediaChange() {
    this.nkMatchMediaService.onMediaChange
      .pipe(
        map(() => this.nkMatchMediaService.observableMedia.isActive('gt-md')),
        distinctUntilChanged(),
        mergeMap((v) =>
          this.store.select(NavigationLayoutSettingStore.navLayoutConfig).pipe(
            take(1),
            map((k) => [v, k])
          )
        )
      )
      .subscribe(
        ([isLargeScreen, currentState]: [
          boolean,
          typeof NavigationLayoutSetting
        ]) => {
          if (isLargeScreen) {
            const data = {
              type: currentState.expected,
              open: null,
              large: true,
            };
            data.open =
              [NavigationType.LEFT, NavigationType.RIGHT].includes(
                currentState.expected
              ) || null;

            this.store.dispatch(new UpdateNavigationLayoutSetting(data));
          } else {
            let data = {
              type: currentState.type,
              open: false,
              large: false,
              expected: currentState.expected,
            };
            if (currentState.type === NavigationType.TOP) {
              data.type = NavigationType.LEFT;
            } else if (currentState.type === NavigationType.NONE) {
              data = {
                type: NavigationType.NONE,
                open: null,
                large: false,
                expected: currentState.type,
              };
            }
            this.store.dispatch(new UpdateNavigationLayoutSetting(data));
          }
        }
      );
  }

  public listenForRouteChange() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        mergeMap((v) =>
          this.store
            .select(NavigationLayoutSettingStore.navLayoutConfig)
            .pipe(take(1))
        ),
        filter((s: typeof NavigationLayoutSetting) => !s.large)
      )
      .subscribe(() =>
        this.store.dispatch(new UpdateNavigationLayoutSetting({ open: false }))
      );
  }

  private canUpdateNavbarLayout(data: {
    type: NavigationType;
    open?: boolean;
    large?: boolean;
  }) {
    if (data.open != null) {
      if ([NavigationType.NONE, NavigationType.TOP].includes(data.type)) {
        throw new Error(
          '[SideBar] open should must be undefined/null for TOP and NONE layout'
        );
      }
    }
    if (!data.large) {
      if (data.type === NavigationType.TOP) {
        throw new Error('[SideBar] can not be in top layout in small screen');
      }
    }

    return true;
  }

  @Action(UpdateNavigationLayoutSetting)
  public UpdateTheme(
    { setState, getState }: StateContext<NavigationLayoutSettingModel>,
    { payload }: any
  ) {
    const newState: NavigationLayoutSettingModel = immerv(
      (s: NavigationLayoutSettingModel) => merge(s.navLayout, payload)
    )(getState());
    try {
      if (this.canUpdateNavbarLayout(newState.navLayout)) {
        setState(newState);
      }
    } catch (e) {
      setState(newState);
      console.error(e.message);
    }
  }

  @Action(UpdateNavigationPosition)
  public updateNavigationPosition(
    { dispatch, getState }: StateContext<NavigationLayoutSettingModel>,
    { payload }: { payload: NavigationType }
  ) {
    const data = {
      type: payload,
      open: null,
      expected: payload,
      large: getState().navLayout.large,
    };

    data.open =
      [NavigationType.LEFT, NavigationType.RIGHT].includes(payload) || null;
    try {
      this.canUpdateNavbarLayout(data);
      return dispatch(new UpdateNavigationLayoutSetting(data));
    } catch (error) {
      return dispatch(new UpdateNavigationLayoutSetting({ expected: payload }));
    }
  }

  @Action(ToggleNavigationOpen)
  public toggleNavigationOpen({
    dispatch,
    getState,
  }: StateContext<NavigationLayoutSettingModel>) {
    return dispatch(
      new UpdateNavigationLayoutSetting({ open: !getState().navLayout.open })
    );
  }

  @Action(ResetNavigationLayout)
  public resetNavigationLayout({
    dispatch,
  }: StateContext<NavigationLayoutSettingModel>) {
    return dispatch(new UpdateNavigationLayoutSetting(defCon));
  }
}
