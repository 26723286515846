<mat-sidenav-container>
  <div id="nk-main-content">
    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="nkSettings.layout.toolbar === 'above'">
      <nk-toolbar
        class="above"
        [ngClass]="nkSettings.colorClasses.toolbar"
      ></nk-toolbar>
    </ng-container>
    <!-- / TOOLBAR: Above -->
    <!-- NAVBAR: Top -->
    <nk-navbar
      layout="horizontal"
      class="top-navbar"
      fxHide
      fxShow.gt-md
      [ngClass]="nkSettings.colorClasses.navbar || 'bg-trans'"
      *ngIf="sideBarConfig.type === 'top'"
    >
    </nk-navbar>
    <!-- / NAVBAR: Top -->
    <div id="wrapper">
      <!-- NAVBAR: Left -->
      <nk-sidebar
        [name]="'navbar'"
        class="left-navbar"
        [config]="sideBarConfig"
        [ngClass]="nkSettings.colorClasses.navbar || 'bg-trans'"
        (backdropClicked)="navbarBackDropClicked()"
        *ngIf="sideBarConfig.type === 'left'"
      >
        <nk-navbar layout="vertical"></nk-navbar>
      </nk-sidebar>
      <!-- / NAVBAR: Left -->
      <div class="content-wrapper">
        <!-- TOOLBAR: Below -->
        <ng-container *ngIf="nkSettings.layout.toolbar === 'below'">
          <nk-toolbar
            class="below"
            [ngClass]="nkSettings.colorClasses.toolbar"
          ></nk-toolbar>
        </ng-container>
        <!-- / TOOLBAR: Below -->
        <nk-content></nk-content>
        <!-- FOOTER: Below -->
        <ng-container *ngIf="nkSettings.layout.footer === 'below'">
          <nk-footer
            class="below"
            [ngClass]="nkSettings.colorClasses.footer"
          ></nk-footer>
        </ng-container>
        <!-- / FOOTER: Below -->
      </div>
      <!-- NAVBAR: Right -->
      <nk-sidebar
        [name]="'navbar'"
        [config]="sideBarConfig"
        class="right-navbar"
        [ngClass]="nkSettings.colorClasses.navbar || 'bg-trans'"
        (backdropClicked)="navbarBackDropClicked()"
        *ngIf="sideBarConfig.type === 'right'"
      >
        <nk-navbar layout="vertical"></nk-navbar>
      </nk-sidebar>
      <!-- / NAVBAR: Right -->
    </div>
    <!-- FOOTER: Above -->
    <ng-container *ngIf="nkSettings.layout.footer === 'above'">
      <nk-footer
        class="above"
        [ngClass]="nkSettings.colorClasses.footer"
      ></nk-footer>
    </ng-container>
    <!-- FOOTER: Above -->
  </div>
  <!-- QUICK PANEL -->
  <mat-sidenav
    nkMatSidenavHelper="quick-panel"
    position="end"
  >
    <nk-quick-panel></nk-quick-panel>
  </mat-sidenav>
  <!-- / QUICK PANEL -->
</mat-sidenav-container>
<nk-theme-options [navigation]="navMenu$ | async"></nk-theme-options>
