<ng-container *ngIf="!item.hidden">
  <!-- item.url -->
  <a
    class="nav-link"
    *ngIf="item.url && !item.function"
    [routerLink]="[item.url]"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    matRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>
  <!-- item.function -->
  <span
    class="nav-link"
    *ngIf="!item.url && item.function"
    (click)="item.function()"
    matRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>
  <!-- item.url && item.function -->
  <a
    class="nav-link"
    *ngIf="item.url && item.function"
    (click)="item.function()"
    [routerLink]="[item.url]"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    matRipple
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>
  <ng-template #itemContent>
    <mat-icon
      class="nav-link-icon"
      *ngIf="item.icon"
    >{{item.icon}}</mat-icon>
    <span
      class="nav-link-title"
      [translate]="item.translate"
    >{{item.title}}</span>
    <span
      class="nav-link-badge"
      *ngIf="item.badge"
      [translate]="item.badge.translate"
      [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}"
    >
      {{item.badge.title}}
    </span>
  </ng-template>
</ng-container>
