import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import * as moment from 'moment';
import {
  AddComment,
  AddRoom,
  UpdateFilter,
  UpdateMeta,
} from './conversation.actions';

export class TalkStoreModel {
  static id = 'TalkStore';
  [room: string]: any;
}

@State<TalkStoreModel>({
  name: 'TalkStore',
  defaults: {},
})
@Injectable()
export class TalkStore {
  @Action(UpdateFilter)
  updateFilter(
    { patchState }: StateContext<TalkStoreModel>,
    { payload }: UpdateFilter
  ) {
    patchState({ filter: payload });
  }

  @Action(AddComment)
  addComment(
    { setState, dispatch }: StateContext<TalkStoreModel>,
    { payload: { room, comments } }: AddComment
  ) {
    if (comments.length < 1) {
      return;
    }
    setState(patch({ [room]: append(comments) }));
    dispatch(
      new UpdateMeta({
        room,
        meta: {
          lastUpdated: moment(comments[comments.length - 1].createdAt)
            // .utc(true)
            .toDate(),
        },
      })
    );
  }

  @Action(AddRoom)
  addRoom(
    { setState, getState }: StateContext<TalkStoreModel>,
    { payload, force }: AddRoom
  ) {
    const delta = getState();
    console.log(delta);
    if (
      force ||
      !(
        delta.hasOwnProperty(payload) && delta.hasOwnProperty(payload + '_META')
      )
    ) {
      setState(patch({ [payload]: [], [payload + '_META']: {} }));
    }
  }

  @Action(UpdateMeta)
  updateMeta(
    { setState }: StateContext<TalkStoreModel>,
    { payload: { room, meta } }: UpdateMeta
  ) {
    setState(patch({ [room + '_META']: meta }));
  }
}
