import { Routes } from '@angular/router';
import { UserLoginGuard } from '../guards/user-login.guard';

export const MainRoutes: Routes = [
  {
    path: 'chat',
    loadChildren: () =>
      import('../pages/chat/chat.module').then((v) => v.ChatModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('../pages/users/users.module').then((v) => v.UsersModule),
    canActivate: [UserLoginGuard],
  },

  {
    path: 'comments',
    loadChildren: () =>
      import('../pages/comments/comments.module').then((v) => v.CommentsModule),
    canActivate: [UserLoginGuard],
  },

  {
    path: 'post',
    loadChildren: () =>
      import('../pages/post/post.module').then((v) => v.PostModule),
    canActivate: [UserLoginGuard],
  },
  {
    path: 'slide',
    loadChildren: () =>
      import('../pages/slide/slide.module').then((v) => v.SlideModule),
    canActivate: [UserLoginGuard],
  },
  {
    path: 'category',
    loadChildren: () =>
      import('../pages/admin/category/category.module').then(
        (v) => v.CategoryModule
      ),
    canActivate: [UserLoginGuard],
  },
  {
    path: 'domains',
    loadChildren: () =>
      import('../pages/domains/domains.module').then((v) => v.DomainModule),
    canActivate: [UserLoginGuard],
  },
  {
    path: 'paymentMode',
    loadChildren: () =>
      import('../pages/payment-mode/payment-mode.module').then(
        (v) => v.PaymentModule
      ),
    canActivate: [UserLoginGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../pages/reports/report.module').then((v) => v.ReportModule),
    canActivate: [UserLoginGuard],
  },
];
