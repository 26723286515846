<!-- <h2 mat-dialog-title>Confirm</h2> -->
<mat-dialog-content
  align="center"
  mat-dialog-content
  [innerHTML]="confirmMessage"
></mat-dialog-content>
<mat-dialog-actions
  align="center"
  mat-dialog-actions
  class="pt24"
>
  <div *ngIf="text_area">
    <textarea
      matInput
      class="m12"
      [(ngModel)]="rejectComment"
      placeholder="Leave a comment"
    ></textarea>
  </div>
  <button
    mat-stroked-button
    [mat-dialog-close]='false'
    (click)="dialogRef.close(false)"
  >Cancel</button>
  <button
    *ngIf='text_area'
    mat-stroked-button
    [mat-dialog-close]='true'
    color="accent"
    class="ml8"
    (click)="dialogRef.close(rejectComment)"
  >Confirm</button>
  <button
    *ngIf='!text_area'
    mat-stroked-button
    [mat-dialog-close]='true'
    color="accent"
    class="ml8"
    (click)="dialogRef.close(true)"
  >Confirm</button>
</mat-dialog-actions>
