import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NkSearchBarComponent } from './search-bar.component';

@NgModule({
  declarations: [NkSearchBarComponent],
  imports: [CommonModule, RouterModule, MatButtonModule, MatIconModule],
  exports: [NkSearchBarComponent],
})
export class NkSearchBarModule {}
