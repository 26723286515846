import { Injectable } from '@angular/core';
import * as picaClient from 'pica';
import { SnackBarService } from '../app-services/snack-bar.service';

const Pica = picaClient();

const dataURLtoBlob = (dataURL: string) => {
  const [type, data] = dataURL.split(',');
  const mime = type.match(/:(.*?);/)[1];
  const bstr = atob(data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result.toString());
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(blob);
  });
};

const fitInside = ([x, y], [w, h]) => (
  x > w && ((y /= x / w), (x = w)),
  y > h && ((x /= y / h), (y = h)),
  [x, y].map(Math.floor)
);

interface UploadThumbOption {
  maxSize?: number;
  type?: RegExp;
  resize?: [number, number];
}

const defaultThumbOption: UploadThumbOption = {
  maxSize: Infinity,
  type: /^image\/(png|jpeg)$/,
  resize: [480, 480],
};

@Injectable({ providedIn: 'root' })
export class ImageService {
  constructor(private readonly snackService: SnackBarService) {}

  public async resize(
    image: HTMLImageElement,
    to: [number, number] = [600, 600]
  ) {
    const res = fitInside([image.naturalWidth, image.naturalHeight], to);
    if (!res) {
      return;
    }
    const canvas = document.createElement('canvas');
    canvas.width = res[0];
    canvas.height = res[1];
    const resized = await Pica.resize(image, canvas, {
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2,
    });
    const blob = await Pica.toBlob(resized, 'image/jpeg', 0.9);
    return await blobToDataURL(blob);
  }

  private async prepareImage(file: File): Promise<HTMLImageElement> {
    return new Promise(async (s, f) => {
      const image = document.createElement('img');
      image.width = 200;
      image.height = 200;
      image.onload = () => s(image);
      image.onerror = f;
      image.src = (await blobToDataURL(file)) as string;
    });
  }

  public async resizeFromFile(file: File, to: [number, number] = [480, 480]) {
    const image = await this.prepareImage(file);
    const res = fitInside([image.naturalWidth, image.naturalHeight], to);
    if (!res) {
      return image.src;
    }
    const canvas = document.createElement('canvas');
    canvas.width = res[0];
    canvas.height = res[1];
    const resized = await Pica.resize(image, canvas, {
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2,
    });
    const blob = await Pica.toBlob(resized, 'image/jpeg', 0.9);
    return await blobToDataURL(blob);
  }

  public async uploadThumbImage(file: File, options: UploadThumbOption = {}) {
    options = { ...defaultThumbOption, ...options };
    if (file.size > options.maxSize) {
      this.snackService.error('Image should be less then 2MB');
      return;
    }
    if (!options.type.test(file.type)) {
      this.snackService.error('Image formats invalids should be png/jpeg');
      return;
    }
    return await this.resizeFromFile(file, options.resize);
  }
}
