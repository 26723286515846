import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { NkSharedModule } from '../../../../shared/shared.module';
import { NkFooterComponent } from './footer.component';

@NgModule({
  declarations: [NkFooterComponent],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    NkSharedModule,
    RouterModule,
  ],
  exports: [NkFooterComponent],
})
export class NkFooterModule {}
