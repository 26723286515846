import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { immerv } from '../../shared/common-packages/immer';

const MODEL_NAME = 'APP_NAVIGATION_MENUS';

export class AddMenuGroup {
  static readonly type = MODEL_NAME + ' AddMenuGroup';
  constructor(public payload: any) {}
}

export class RemoveMenuGroup {
  static readonly type = MODEL_NAME + ' RemoveMenuGroup';
}

export class AddMenu {
  static readonly type = MODEL_NAME + ' AddMenu';
  constructor(public payload: any) {}
}

export class RemoveMenu {
  static readonly type = MODEL_NAME + ' RemoveMenu';
  constructor() {}
}

export class ResetMenu {
  static readonly type = MODEL_NAME + ' ResetMenu';
  constructor() {}
}

export class SideMenuModel {
  static id = MODEL_NAME;
  nav: any[];
}

@State<SideMenuModel>({
  name: MODEL_NAME,
  defaults: { nav: [] },
})
@Injectable()
export class SideMenuStore implements NgxsOnInit {
  constructor() {}

  @Selector()
  public static navMenu(state: SideMenuModel) {
    return state.nav;
  }

  public ngxsOnInit() {}

  @Action(AddMenuGroup)
  public AddMenuGroup(
    { setState, getState }: StateContext<SideMenuModel>,
    { payload }: any
  ) {
    setState(immerv((s: SideMenuModel) => s.nav.push(payload)));
  }

  // @Action(RemoveMenuGroup)
  // public RemoveMenuGroup(
  //   { dispatch, getState }: StateContext<SideMenuModel>,
  //   { payload }: { payload: NavigationType }
  // ) {}

  @Action(AddMenu)
  public AddMenu(
    { setState, getState }: StateContext<SideMenuModel>,
    { payload }: any
  ) {
    setState(immerv((s: SideMenuModel) => s.nav.push(payload)));
  }

  // @Action(RemoveMenu)
  // public RemoveMenu({ dispatch }: StateContext<SideMenuModel>) {

  // }

  @Action(ResetMenu)
  public ResetMenu({ setState }: StateContext<SideMenuModel>) {
    setState(immerv((s: SideMenuModel) => (s.nav = [])));
  }
}
