import * as moment from 'moment';
import { Moment } from 'moment';

export class TokenData {
  public refresh_token: any;
  public accessToken: any;
  public token_type: any;
  constructor(data) {
    this.refresh_token = data['refresh_token'];
    this.accessToken = data['accessToken'];
    this.token_type = data['token_type'];
  }
}
export class OTPDemand {
  public otp_required: boolean;
  constructor(data) {
    this.otp_required = !!data['otp_required'];
  }
}

// createdAt: "2020-08-26T15:17:53.227Z"
// email: "Garrett_Toy58@hotmail.com"
// gender: "female"
// id: 1
// isActive: true
// meta: {}
// mobile: "715.259.1824"
// name: "Teri Fritsch"
// roles: [{id: 2, role: "admin"}, {id: 3, role: "super_admin"}]
// updatedAt: "2020-12-21T10:18:09.000Z"
// username: "superadmin"
export class LoginUserDetails {
  public id: number;
  public name: string;
  public username: string;
  public email: string;
  public pic: string;
  public gender: string;
  public isActive: boolean;
  public meta: any;
  public mobile: string;
  public roles: { id: number; role: string }[];
  public createdAt: Moment;
  public updatedAt: Moment;
  constructor(data: any = {}) {
    data = data.data || {};
    this.id = data.id ?? null;
    this.name = data.name ?? null;
    this.username = data.username ?? null;
    this.email = data.email ?? null;
    this.gender = data.gender ?? null;
    this.isActive = data.isActive ?? true;
    this.meta = data.meta ?? {};
    this.mobile = data.mobile ?? null;
    this.pic = data.pic ?? null;
    this.roles = data.roles ?? [];
    // if (this.roles.length === 0) {
    //   this.roles.push({ id: 1, role: 'user' });
    // }
    this.createdAt = moment(data.createdAt);
    this.updatedAt = moment(data.updatedAt);
  }

  hasRole(role: string) {
    return this.roles.map((v) => v.role).includes(role);
  }
}
