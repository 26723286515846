// import { UIPermission } from 'app/configs/roles-permission.config';
export const applications = {
  id: 'applications',
  title: 'Applications',
  translate: 'Applications',
  type: 'group',
  children: [
    {
      id: 'documents',
      title: 'Documents',
      // perm: UIPermission.Documents.canView,
      type: 'item',
      icon: 'email',
      url: '/documents',
    },
    {
      id: 'messenger',
      title: 'Messenger',
      // perm: UIPermission.Messengers.canView,
      type: 'item',
      icon: 'message',
      url: '/messenger',
    },
    {
      id: 'post',
      title: 'Post',
      // perm: UIPermission.Messengers.canView,
      type: 'item',
      icon: 'post_add',
      url: '/post',
    },
    {
      id: 'news',
      title: 'News',
      // perm: UIPermission.News.canView,
      type: 'item',
      icon: 'chrome_reader_mode',
      url: '/news',
    },
    {
      id: 'promotions',
      title: 'Promotions',
      // perm: UIPermission.Promotion.canView,
      type: 'item',
      icon: 'local_offer',
      url: '/promotion',
    },
    {
      id: 'event_calendar',
      title: 'Events',
      // perm: UIPermission.canEvent,
      type: 'item',
      icon: 'event',
      url: '/event',
    },
    {
      id: 'slide',
      title: 'Slide',
      // perm: UIPermission.canSeeAdminList,
      type: 'item',
      icon: 'supervised_user_circle',
      url: '/slide',
    },
    // {
    //   id: 'report',
    //   title: 'Reports',
    //   // perm: UIPermission.canReport,
    //   type: 'item',
    //   icon: 'report_problem',
    //   url: '/report',
    // },
    // {
    //   id: 'e-claim',
    //   title: 'E-Claims',
    //   // perm: UIPermission.canReport,
    //   type: 'item',
    //   icon: 'local_florist',
    //   url: '/e-claim',
    // },
    // {
    //   id: 'e-receipt',
    //   title: 'E-Receipt',
    //   // perm: UIPermission.canReport,
    //   type: 'item',
    //   icon: 'receipt',
    //   url: '/e-receipt',
    // },
    // {
    //   id: 'forum',
    //   title: 'Forum',
    //   // perm: UIPermission.canSeeAdminList,
    //   type: 'item',
    //   icon: 'forum',
    //   url: '/forum',
    // },
    // {
    //   id: 'info',
    //   title: 'Knowledge Base',
    //   // perm: UIPermission.Info.view,
    //   type: 'item',
    //   icon: 'info',
    //   url: '/info',
    // },
    // {
    //   id: 'airoster',
    //   title: 'Airoster',
    //   // perm: UIPermission.Airoster.view,
    //   type: 'item',
    //   icon: 'airplanemode_active',
    //   url: '/airoster',
    // },
    // {
    //   id: 'e_banner',
    //   title: 'E-Banner',
    //   // perm: UIPermission.canReport,
    //   type: 'item',
    //   icon: 'aspect_ratio',
    //   url: '/banner',
    // },
    // {
    //   id: 'survey',
    //   title: 'Survey',
    //   // perm: UIPermission.Survey.view,
    //   type: 'item',
    //   icon: 'bar_chart',
    //   url: '/survey',
    // },
  ],
};
