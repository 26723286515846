import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AllThemes } from '../../configs/theme.config';
import { AppThemeModel } from '../../stores/app/theme.state';

@Injectable()
export class ThemesService {
  theme: keyof typeof AllThemes = 'default';
  @Select((s: any) => s[AppThemeModel.id].appTheme.layout.theme)
  themeConfig$!: Observable<any>;

  constructor() {}

  init() {
    return this.themeConfig$.pipe(
      tap((v: keyof typeof AllThemes) => {
        if (AllThemes[this.theme].klass) {
          document.body.classList.remove(`${AllThemes[this.theme].klass}`);
        }
        this.theme = v;
        if (AllThemes[this.theme].klass) {
          document.body.classList.add(`${AllThemes[this.theme].klass}`);
        }
      })
    );
  }
}
