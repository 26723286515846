import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../pages/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class UserLoginGuard implements CanActivate {
  constructor(public authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isLogin = this.authService.isUserLogin();
    if (!isLogin) {
      this.sendToLogin(state.url);
      return false;
    }
    // const hasPermission = this.loginService.hasPermission(next.data.per);
    // if (!hasPermission) {
    //   this.sendToRoot(['/']);
    //   return false;
    // }

    return true;
  }

  private sendToRoot(url) {
    this.router.navigate(url);
  }

  private sendToLogin(url) {
    this.router.navigate(['/auth/login'], {
      queryParams: { return: url },
    });
  }
}
