import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { NkSharedModule } from '../../../../shared/shared.module';
import { NkQuickPanelComponent } from './quick-panel.component';
@NgModule({
  declarations: [NkQuickPanelComponent],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MomentModule,
    NkSharedModule,
    RouterModule,
    MatCardModule,
    MatIconModule,
    NkSharedModule,
  ],
  exports: [NkQuickPanelComponent],
})
export class NkQuickPanelModule {}
