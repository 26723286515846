import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AppThemeModel } from '../../stores/app/theme.state';

@Component({
  selector: 'nk-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class NkSearchBarComponent implements OnDestroy {
  collapsed: boolean;
  toolbarColor: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onInput: EventEmitter<any> = new EventEmitter();

  onConfigChanged: Subscription;

  @Select((s) => s[AppThemeModel.id].appTheme.colorClasses.toolbar)
  toolbarClass$: Observable<any>;

  constructor() {
    this.collapsed = true;
    this.onConfigChanged = this.toolbarClass$.subscribe(
      (klass) => (this.toolbarColor = klass)
    );
  }

  collapse() {
    this.collapsed = true;
  }

  expand() {
    this.collapsed = false;
  }

  search(event) {
    const value = event.target.value;
    this.onInput.emit(value);
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }
}
