import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';

function isEmptyInputValue(value: any): boolean {
  return value == null || value.length === 0;
}

function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

function isDate(obj: any): boolean {
  return !/Invalid|NaN/.test(new Date(obj).toString());
}

function parseDate(obj: any): string {
  if (
    typeof obj === 'object' &&
    obj.year != null &&
    obj.month != null &&
    obj.day != null
  ) {
    return obj.year + '-' + obj.month + '-' + obj.day;
  }
  return obj;
}

const uuids = {
  '3': /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  '4': /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  '5': /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
};

export function birthYear(c: FormControl): ValidationErrors {
  const numValue = Number(c.value);
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 85;
  const maxYear = currentYear - 18;
  const isValid =
    !isNaN(numValue) && numValue >= minYear && numValue <= maxYear;
  const message = {
    years: {
      message:
        'The year must be a valid number between ' +
        minYear +
        ' and ' +
        maxYear,
    },
  };
  return isValid ? null : message;
}

export function countryCity(form: FormGroup): ValidationErrors {
  const countryControl = form.get('location.country');
  const cityControl = form.get('location.city');
  if (countryControl != null && cityControl != null) {
    const country = countryControl.value;
    const city = cityControl.value;
    let error = null;
    if (country === 'France' && city !== 'Paris') {
      error = 'If the country is France, the city must be Paris';
    }
    const message = {
      countryCity: {
        message: error,
      },
    };
    return error ? message : null;
  }
}

export function uniqueName(c: FormControl): Promise<ValidationErrors> {
  const message = {
    uniqueName: {
      message: 'The name is not unique',
    },
  };
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(c.value === 'Existing' ? message : null);
    }, 1000);
  });
}

export function telephoneNumber(c: FormControl): ValidationErrors {
  const isValidPhoneNumber = /^\d{3,3}-\d{3,3}-\d{3,3}$/.test(c.value);
  const message = {
    telephoneNumber: {
      message:
        'The phone number must be valid (XXX-XXX-XXX, where X is a digit)',
    },
  };
  return isValidPhoneNumber ? null : message;
}

export function telephoneNumbers(form: FormGroup): ValidationErrors {
  const message = {
    telephoneNumbers: {
      message: 'At least one telephone number must be entered',
    },
  };
  const phoneNumbers = <FormArray>form.get('phoneNumbers');
  const hasPhoneNumbers =
    phoneNumbers && Object.keys(phoneNumbers.controls).length > 0;
  return hasPhoneNumbers ? null : message;
}

export function gt(v: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || isEmptyInputValue(v)) {
      return null;
    }
    const value = parseFloat(control.value);
    return value <= v ? { gt: { gt: v, actual: control.value } } : null;
  };
}

export function numberValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const value = +control.value;
  return isNaN(value) ? { number: true } : null;
}

export function base64(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  return /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i.test(
    v
  )
    ? null
    : { base64: true };
}

export function date(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  return isDate(v) ? null : { date: true };
}

export function dateISO(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(v)
    ? null
    : { dateISO: true };
}

export function digits(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  return /^\d+$/.test(v) ? null : { digits: true };
}

export function equal(value: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: any = control.value;
    return value === v
      ? null
      : { equal: { equal: value, actual: control.value } };
  };
}

export function gte(value: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: number = +control.value;
    return v < value ? { gte: { gte: value, actual: control.value } } : null;
  };
}

export function json(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  try {
    const obj = JSON.parse(v);
    if (Boolean(obj) && typeof obj === 'object') {
      return null;
    }
  } catch (e) {}
  return { json: true };
}

export function lt(value: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: number = +control.value;
    return v < +value ? null : { lt: { lt: value, actual: control.value } };
  };
}

export function lte(value: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: number = +control.value;
    return v <= +value ? null : { lte: { lte: value, actual: control.value } };
  };
}

export function maxDate(value: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    const v = value;
    value = parseDate(value);
    if (!isDate(value) && !(value instanceof Function)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const d = new Date(control.value).getTime();
    if (!isDate(d)) {
      return { value: true };
    }
    if (value instanceof Function) {
      value = value();
    }
    return d <= new Date(value).getTime()
      ? null
      : { maxDate: { maxDate: v, actual: control.value } };
  };
}

export function minDate(value: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = value;
    value = parseDate(value);
    if (!isDate(value) && !(value instanceof Function)) {
      return null;
    }
    const d = new Date(control.value).getTime();
    if (!isDate(d)) {
      return { value: true };
    }
    if (value instanceof Function) {
      value = value();
    }
    return d >= new Date(value).getTime()
      ? null
      : { minDate: { minDate: v, actual: control.value } };
  };
}

export function notEqual(value: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: any = control.value;
    return value !== v
      ? null
      : { notEqual: { notEqual: value, actual: control.value } };
  };
}

export function range(value: number[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: number = +control.value;
    return v >= value[0] && v <= value[1]
      ? null
      : { range: { range: value, actual: control.value } };
  };
}

export function rangeLength(value: number[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: string = control.value;
    return v.length >= value[0] && v.length <= value[1]
      ? null
      : { rangeLength: { rangeLength: value, actual: control.value } };
  };
}

export function uuid(version?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v: string = control.value;
    const pattern = uuids[version] || uuids.all;
    return new RegExp(pattern).test(v) ? null : { uuid: true };
  };
}

export function url(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  return RegExp(
    `^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})\
(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})\
(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\
\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)\
(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]\
{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$`,
    'i'
  ).test(v)
    ? null
    : { url: true };
}

export function key(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  return /^[a-z_]+$/.test(v) ? null : { key: true };
}

export function uniqueKey(
  allControls: AbstractControl[],
  uniqueProp: string
): ValidatorFn {
  // let subscribe = false;
  return (control: AbstractControl) => {
    // if (!subscribe) {
    // subscribe = true;
    return [...allControls.values()].filter(
      (v) => v['controls'][uniqueProp].value === control.value
    ).length > 1
      ? { uniqueKey: true }
      : null;

    // allControls.valueChanges.subscribe(() => {
    //   control.updateValueAndValidity();
    // });
    // }

    // const v = control.value;
    // return allControls.value === v
    //   ? null
    //   : { equalTo: { equalTo: allControls.value, actual: v } };
  };
}

export function domain(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value) && !isPresent(control.value)) {
    return null;
  }
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  return /^[a-z]+$/.test(v) ? null : { domain: true };
}

export function includes(
  value: string[],
  caseSensitive: boolean = false
): ValidatorFn {
  if (!caseSensitive) {
    value = value.map((v) => v.toLowerCase());
  }
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    let v: string = control.value;
    if (!caseSensitive) {
      v = v.toLowerCase();
    }
    return value.includes(v)
      ? null
      : { includes: { includes: value, actual: control.value } };
  };
}
export function notIncludes(
  value: string[],
  caseSensitive: boolean = false
): ValidatorFn {
  if (!caseSensitive) {
    value = value.map((v) => v.toLowerCase());
  }
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      isEmptyInputValue(control.value) &&
      !isPresent(control.value) &&
      isEmptyInputValue(value)
    ) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    let v: string = control.value;
    if (!caseSensitive) {
      v = v.toLowerCase();
    }
    return value.includes(v)
      ? { notIncludes: { notIncludes: value, actual: control.value } }
      : null;
  };
}

export function equalTo(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;
  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    const v = control.value;
    return equalControl.value === v
      ? null
      : { equalTo: { equalTo: equalControl.value, actual: v } };
  };
}
export function dMinDate(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;

  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (!equalControl.value || !control.value) {
      return null;
    }
    const v = moment(control.value).toDate();
    return moment(equalControl.value).toDate() >= v
      ? null
      : { lessThen: { equalTo: equalControl.value, actual: v } };
  };
}
export function dMaxDate(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;

  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (!equalControl.value || !control.value) {
      return null;
    }
    const v = moment(control.value).toDate();
    return moment(equalControl.value).toDate() <= v
      ? null
      : { maxDate: { equalTo: equalControl.value, actual: v } };
  };
}
export function dMinNumber(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;
  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (!equalControl.value || !control.value) {
      return null;
    }
    // console.log(equalControl.value, +control.value);
    return equalControl.value >= +control.value
      ? null
      : { lessThen: { equalTo: equalControl.value, actual: control.value } };
  };
}
export function dMaxNumber(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;
  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (!equalControl.value || !control.value) {
      return null;
    }
    // console.log(equalControl.value, +control.value);
    return equalControl.value <= +control.value
      ? null
      : { MaxNumber: { equalTo: equalControl.value, actual: control.value } };
  };
}
export function dMinValue(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;
  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (!equalControl.value || !control.value) {
      return null;
    }
    return control.value.length >= equalControl.value
      ? null
      : { minLength: { minValue: equalControl.value, actual: control.value } };
  };
}
export function dMaxValue(equalControl: AbstractControl): ValidatorFn {
  let subscribe = false;
  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (!equalControl.value || !control.value) {
      return null;
    }
    return control.value.length <= equalControl.value
      ? null
      : { maxLength: { maxValue: equalControl.value, actual: control.value } };
  };
}

export function notEqualTo(notEqualControl: AbstractControl): ValidatorFn {
  let subscribe = false;
  return (control: AbstractControl) => {
    if (!subscribe) {
      subscribe = true;
      notEqualControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    const v = control.value;
    return notEqualControl.value !== v
      ? null
      : { notEqualTo: { notEqualTo: notEqualControl.value, actual: v } };
  };
}
