import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NkNavigationModule } from '../../../../components';
import { NkSharedModule } from '../../../../shared/shared.module';
import { NkNavbarComponent } from './navbar.component';

@NgModule({
  declarations: [NkNavbarComponent],
  imports: [
    MatButtonModule,
    MatIconModule,
    NkNavigationModule,
    NkSharedModule,
    RouterModule,
  ],
  exports: [NkNavbarComponent],
})
export class NkNavbarModule {}
