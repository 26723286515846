import { NgModule } from '@angular/core';
import { NkIfOnDomDirective } from './nk-if-on-dom.directive';
import {
  NkMatSidenavHelperDirective,
  NkMatSidenavTogglerDirective,
} from './nk-mat-sidenav.directive';

const modules = [
  NkIfOnDomDirective,
  NkMatSidenavHelperDirective,
  NkMatSidenavTogglerDirective,
  // NkPerfectScrollbarDirective,
];
@NgModule({ declarations: modules, exports: modules })
export class NkDirectivesModule {}
