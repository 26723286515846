import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nk-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NkNavigationComponent {
  @Input() layout = 'vertical';
  @Input() navigation: any;

  constructor() {}
}
