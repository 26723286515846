import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { defaults } from '../../shared/common-packages/lodash';

export enum SnackStyle {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARN = 'warn',
  NORMAL = 'normal',
}

const defaultOptions = {
  action: 'Close',
  style: SnackStyle.NORMAL,
  duration: 3000,
  horizontalPosition: 'center',
  verticalPosition: 'top',
};

const panelClasses = {
  error: 'mat-red-bg',
  info: 'mat-blue-grey-bg',
  success: 'mat-green-bg',
  warn: 'mat-yellow-bg',
  normal: '',
};
@Injectable({ providedIn: 'root' })
export class SnackBarService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone
  ) {}

  public normal(message: string, action?: string, options?: MatSnackBarConfig) {
    this.open(message, action, { ...options, style: SnackStyle.NORMAL });
  }

  public warn(message: string, action?: string, options?: MatSnackBarConfig) {
    this.open(message, action, { ...options, style: SnackStyle.WARN });
  }

  public info(message: string, action?: string, options?: MatSnackBarConfig) {
    this.open(message, action, { ...options, style: SnackStyle.INFO });
  }

  public error(message: string, action?: string, options?: MatSnackBarConfig) {
    this.open(message, action, { ...options, style: SnackStyle.ERROR });
  }

  public success(
    message: string,
    action?: string,
    options?: MatSnackBarConfig
  ) {
    this.open(message, action, { ...options, style: SnackStyle.SUCCESS });
  }

  public open(
    message: string,
    action?: string,
    options?: {
      style?: SnackStyle;
    } & MatSnackBarConfig<any>
  ) {
    const opts: { style?: SnackStyle } & MatSnackBarConfig<any> = defaults(
      options,
      defaultOptions
    );
    const { style, ...conf } = opts;
    conf.panelClass = style ? panelClasses[style] : '';
    this.show(message, undefined, conf);
  }

  private show(
    message: string,
    action: string | undefined,
    configuration: MatSnackBarConfig
  ) {
    this.zone.run(() => this.snackBar.open(message, undefined, configuration));
  }
  /*  public async showSnackBarI18n(key: string, type: string) {
    const result = await this.translate.get(key).toPromise();
    this.showSnackBar(result, type);
  } */
}
