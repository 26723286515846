export const fileToDataURL = (x: File) => {
  return new Promise((s) => {
    const reader = new FileReader();
    reader.onload = (e) => s(e.target.result);
    reader.readAsDataURL(x);
  });
};

export const dataURLToFile = async (v: string, name: string, type: string) => {
  const data = await fetch(v);
  const buffer = await data.arrayBuffer();
  return new File([buffer], name, { type });
};
