export const pages = {
  id: 'pages',
  title: 'Pages',
  translate: 'Pages',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      icon: 'network_check',
      exactMatch: true,
      url: '/',
    },
    {
      id: 'group',
      title: 'Groups',
      type: 'item',
      icon: 'group',
      url: '/group',
    },
    {
      id: 'category',
      title: 'Categories',
      type: 'item',
      icon: 'category',
      url: '/category',
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      icon: 'supervised_user_circle',
      url: '/users',
    },
  ],
};
