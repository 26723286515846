import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { Routes } from '../../configs/api.router.config';
import { SessionExpireComponent } from '../../modals/session-expire/session-expire.component';
import { TokenDialogComponent } from '../../modals/token-dialog/session-expire.component';
import {
  LoginUserDetails,
  OTPDemand,
  TokenData,
} from '../../models/login-module';
import { SnackBarService } from '../../services/app-services/snack-bar.service';
import { UserGlobalService } from '../../services/user-global.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public user = null;
  private sessionExpireDialog: MatDialogRef<SessionExpireComponent, any>;
  private _tokenDialog;
  constructor(
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private readonly userGlobalService: UserGlobalService
  ) {}

  public login(username, password) {
    console.log(username, password);
    return this.http.post(Routes.Login, { username, password }).pipe(
      map((data: any) =>
        data.otp_required ? new OTPDemand(data) : new TokenData(data)
      ),
      tap((tokenData) => {
        if (tokenData instanceof TokenData) {
          this.userGlobalService.auth = tokenData;
        }
      })
    );
  }

  public getUserDetails() {
    return this.http.get(Routes.userLoginDetails, {}).pipe(
      map((data) => new LoginUserDetails(data)),
      tap((data) => this.userGlobalService.updatePermissions(data)),
      tap((userDetails) => (this.userGlobalService.user = userDetails))
    );
  }

  public registration(userData) {
    return this.http.post(Routes.Registration, userData, {}).pipe();
  }

  public registrationFb(userData) {
    return this.http.post(Routes.fbRegistration, userData, {}).pipe(
      map((data: any) =>
        data.otp_required ? new OTPDemand(data) : new TokenData(data)
      ),
      tap((tokenData) => {
        console.log(tokenData);
        if (tokenData instanceof TokenData) {
          this.userGlobalService.auth = tokenData;
        }
      })
    );
  }

  isUserLogin() {
    if (this.user) {
      return true;
    }
    const data = localStorage.getItem('poiuytrewq');
    if (data) {
      this.user = data;
      return true;
    }

    return false;
  }

  public setPassword(data) {
    return this.http.post(Routes.SetPassword, data);
  }
  public verifyEmail(data) {
    return this.http.post(Routes.VerifyEmail, data);
  }

  logout() {
    localStorage.removeItem('poiuytrewq');
    this.user = null;
    this.router.navigate(['/auth/login']);
    return of({ done: true });
  }

  public verifyEmailId(email) {
    const data = { email };
    return this.http.post(Routes.VerifyEmailId, data);
  }

  public async sessionExpire() {
    if (this.sessionExpireDialog) {
      return;
    }
    this.sessionExpireDialog = this.dialog.open(SessionExpireComponent, {
      width: '100vw',
      height: '80vh',
      data: {
        // isAdmin,
        // userId: this.userId,
        user: this.userGlobalService.user,
      },
    });
    this.sessionExpireDialog.afterClosed().subscribe((pwd) => {
      this.sessionExpireDialog = undefined;
      if (pwd) {
        this.login(this.userGlobalService.user.email, pwd)
          .pipe(
            catchError((error) => {
              this.snackBarService.error('RE-LOGIN ERROR, PLEASE LOGOUT');
              return throwError(error);
            }),
            mergeMap((v: any) => {
              if (v.OTPRequired) {
                this.snackBarService.success(
                  'An OTP has been sent to your email'
                );
                return this.askOTP().pipe(
                  take(1),
                  mergeMap((data: any) => {
                    if (!data.token) {
                      this.snackBarService.error('Please, Enter Token');
                      return throwError(new Error(data));
                    } else {
                      const token = data.token;
                      return this.verify2FA(
                        this.userGlobalService.user.email,
                        token
                      ).pipe(
                        catchError((error) => {
                          this.snackBarService.error(
                            'Token is invalid or expired'
                          );
                          return throwError(error);
                        })
                      );
                    }
                  })
                );
              }
              return of(v);
            })
          )
          .subscribe(() => this.snackBarService.success('RE-LOGIN SUCCESSFUL'));
      }
    });
  }

  public verify2FA(username, token) {
    return this.http
      .post(Routes.v2f, {
        username,
        token,
      })
      .pipe(
        map((data) => new TokenData(data)),
        tap((userData) => {
          this.userGlobalService.auth = userData;
        })
      );
  }

  public askOTP() {
    if (this._tokenDialog) {
      return;
    }
    this._tokenDialog = this.dialog.open(TokenDialogComponent, {
      width: '320px',
      data: {
        // isAdmin,
        // userId: this.userId,
        user: this.userGlobalService.user,
      },
    });
    return this._tokenDialog
      .afterClosed()
      .pipe(map((token) => token || { canceled: true }));
  }
}
