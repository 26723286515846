import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { NkSidebarService } from './sidebar.service';

interface IConfig {
  type: string;
  large: boolean;
  open: boolean;
}

@Component({
  selector: 'nk-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NkSidebarComponent implements OnInit, OnDestroy {
  #config: IConfig = {
    type: 'right',
    large: false,
    open: true,
  };

  @Input() name: string;

  @Input()
  set config(value: IConfig) {
    this.updateSideBar(this.#config, value);
    this.#config = value;
  }

  get config() {
    return this.#config;
  }

  @HostBinding('class.open') opened: boolean;
  @HostBinding('class.locked-open') isLockedOpen: boolean;
  @HostBinding('class.folded')
  set folded(value: boolean) {
    // Only work if the sidebar is not closed

    if (!this.opened) {
      return;
    }

    // Set the folded
    this._folded = value;

    // Programmatically add/remove margin to the element
    // that comes after or before based on the alignment
    let sibling, styleRule;

    const styleValue = '64px';

    // Get the sibling and set the style rule
    if (this.config.type === 'left') {
      sibling = this.elementRef.nativeElement.nextElementSibling;
      styleRule = 'marginLeft';
    } else {
      sibling = this.elementRef.nativeElement.previousElementSibling;
      styleRule = 'marginRight';
    }

    // If there is no sibling, return...
    if (!sibling) {
      return;
    }

    // If folded...
    if (value) {
      // Set the style
      this.renderer.setStyle(sibling, styleRule, styleValue);
    }
    // If unfolded...
    else {
      // Remove the style
      this.renderer.removeStyle(sibling, styleRule);
    }
  }

  get folded(): boolean {
    return this._folded;
  }

  // Folded unfolded
  @HostBinding('class.unfolded')
  unfolded: boolean;

  @Output() backdropClicked = new EventEmitter<any>();
  // Private
  private _folded: boolean;
  private _backdrop: HTMLElement | null = null;
  private _player: AnimationPlayer;

  constructor(
    private animationBuilder: AnimationBuilder,
    private elementRef: ElementRef,
    private nkSidebarService: NkSidebarService,
    private renderer: Renderer2
  ) {
    // Set the defaults
    this.opened = false;
    this.folded = false;
  }

  ngOnInit(): void {
    // Register the sidebar
    this.nkSidebarService.register(this.name, this);

    // Setup alignment
    this.setupAlignment(this.config.type);

    // Setup lockedOpen
    this.setupLockedOpen(this.config);
  }

  public ngOnDestroy(): void {
    // If the sidebar is folded, unfold it to revert modifications
    if (this.folded) {
      this.folded = false;
    }

    // Unregister the sidebar
    this.nkSidebarService.unregister(this.name);
  }

  private updateSideBar(oldConfig: IConfig, newConfig: IConfig) {
    this.setupAlignment(newConfig.type);
    this.setupLockedOpen(newConfig);
  }

  /**
   * Set the sidebar alignment
   */
  private setupAlignment(align: string): void {
    this.renderer.addClass(this.elementRef.nativeElement, align + '-aligned');
  }

  /**
   * Setup the lockedOpen handler
   */
  private setupLockedOpen(config: IConfig): void {
    if (config.large) {
      this.hideBackdrop();
      this.isLockedOpen = true;
      if (config.open) {
        this.folded = false;
        this.opened = config.open;
      } else {
        this.folded = true;
        this.opened = true;
      }
    } else {
      this.isLockedOpen = false;
      this.folded = false;
      if (config.open) {
        this.opened = config.open;
        this.showBackdrop();
      } else {
        this.opened = false;
        this.hideBackdrop();
      }
    }
  }

  @HostListener('mouseenter')
  onMouseEnter = () => this.folded && (this.unfolded = true);

  @HostListener('mouseleave')
  onMouseLeave = () => this.folded && (this.unfolded = false);

  /**
   * Show the backdrop
   */
  private showBackdrop(): void {
    if (this._backdrop) {
      return;
    }
    // Create the backdrop element
    this._backdrop = this.renderer.createElement('div');

    // Add a class to the backdrop element
    this._backdrop.classList.add('nk-sidebar-overlay');

    // Append the backdrop to the parent of the sidebar
    this.renderer.appendChild(
      this.elementRef.nativeElement.parentElement,
      this._backdrop
    );

    // Create the enter animation and attach it to the player
    this._player = this.animationBuilder
      .build([animate('300ms ease', style({ opacity: 1 }))])
      .create(this._backdrop);

    // Play the animation
    this._player.play();

    // Add an event listener to the overlay
    this._backdrop.addEventListener('click', () =>
      this.backdropClicked.emit(true)
    );
  }

  /**
   * Hide the backdrop
   */
  private hideBackdrop(): void {
    if (!this._backdrop) {
      return;
    }

    // Create the leave animation and attach it to the player
    this._player = this.animationBuilder
      .build([animate('300ms ease', style({ opacity: 0 }))])
      .create(this._backdrop);

    // Play the animation
    this._player.play();

    // Once the animation is done...
    this._player.onDone(() => {
      // If the backdrop still exists...
      if (this._backdrop) {
        // Remove the backdrop
        this._backdrop.parentNode.removeChild(this._backdrop);
        this._backdrop = null;
      }
    });
  }
}
