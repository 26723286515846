import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'nk-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class NkConfirmDialogComponent {
  public confirmMessage: string;
  public text_area: boolean;
  rejectComment = '';
  constructor(
    public dialogRef: MatDialogRef<NkConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.confirmMessage = _data.confirmMessage;
    this.text_area = _data.text_area;
  }
}
