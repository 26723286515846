import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MainPanelActions } from '../../actions/app/main-panel.actions';
import {
  ResetThemeAll,
  UpdateColorClassesFooter,
  UpdateColorClassesNavbar,
  UpdateColorClassesToolbar,
  UpdateCustomScrollbars,
  UpdateLayoutFooter,
  UpdateLayoutMode,
  UpdateLayoutTheme,
  UpdateLayoutToolbar,
  UpdateRouterAnimation,
} from '../../actions/app/theme.action';
import { AppAnimations } from '../../animations';
import { AllThemes } from '../../configs/theme.config';
import {
  NavigationLayoutSettingStore,
  NavigationType,
  UpdateNavigationPosition,
} from '../../stores/app/navbar.state';
import { AppThemeStore, IMainPanelState } from '../../stores/app/theme.state';

@Component({
  selector: 'nk-theme-options',
  templateUrl: './theme-options.component.html',
  styleUrls: ['./theme-options.component.scss'],
  animations: AppAnimations,
})
export class NkThemeOptionsComponent implements OnInit, OnDestroy {
  @Input() navigation;
  @ViewChild('openButton', { static: true })
  private openButton;

  @ViewChild('panel', { static: true })
  private panel;

  @ViewChild('overlay', { static: true })
  private overlay: ElementRef;

  public player: AnimationPlayer;
  public config: any;
  public themes = AllThemes;

  onConfigChanged: Subscription;

  @HostBinding('class.bar-closed')
  barClosed: boolean;

  @Select(AppThemeStore.appThemeConfig)
  appThemeConfig$: Observable<any>;

  @Select(NavigationLayoutSettingStore.navLayoutConfig)
  navLayoutConfig$: Observable<any>;

  @Select(AppThemeStore.MainPanelState)
  mainPanelState$: Observable<any>;
  mainPanelStateSubscriber: Subscription;

  constructor(
    private readonly animationBuilder: AnimationBuilder,
    private readonly store: Store
  ) {
    this.barClosed = true;

    // this.onConfigChanged = this.nkConfig.onConfigChanged.subscribe(
    //   (newConfig) => {
    //     this.config = newConfig;
    //   }
    // );
  }

  ngOnInit() {
    /*  this.renderer.listen(this.overlay.nativeElement, 'click', () => {
      this.closeBar();
    });
 */
    // Get the nav model and add customize nav item
    // that opens the bar programmatically
    // const nav: any = this.navigation;
    // nav.push({
    //   id: 'custom-function',
    //   title: 'Custom Function',
    //   type: 'group',
    //   children: [
    //     {
    //       id: 'customize',
    //       title: 'Customize',
    //       type: 'item',
    //       icon: 'settings',
    //       function: () => {
    //         this.openBar();
    //       }
    //     }
    //   ]
    // });

    this.mainPanelStateSubscriber = this.mainPanelState$
      .pipe(
        // skip(1),
        map((v: IMainPanelState) => v.themeOptionPanelOpen),
        distinctUntilChanged()
      )
      .subscribe((v) => (v ? this.showBar() : this.hideBar()));
  }

  ngOnDestroy() {
    // this.onConfigChanged.unsubscribe();
    this.mainPanelStateSubscriber.unsubscribe();
  }

  // onSettingsChange() {
  //   this.nkConfig.setConfig(this.config);
  // }

  closeBar() {
    this.store.dispatch(new MainPanelActions.ThemeOptionPanelOpen(false));
  }

  openBar() {
    this.store.dispatch(new MainPanelActions.ThemeOptionPanelOpen(true));
  }

  hideBar() {
    this.player = this.animationBuilder
      .build([
        style({ transform: 'translate3d(0,0,0)' }),
        animate(
          '400ms cubic-bezier(0.4, 0, 0.2, 1)',
          style({ transform: 'translate3d(100%,0,0)' })
        ),
      ])
      .create(this.panel.nativeElement);

    this.player.play();

    this.player.onDone(() => (this.barClosed = true));
  }

  showBar() {
    this.barClosed = false;

    this.player = this.animationBuilder
      .build([
        style({ transform: 'translate3d(100%,0,0)' }),
        animate(
          '400ms cubic-bezier(0.4, 0, 0.2, 1)',
          style({ transform: 'translate3d(0,0,0)' })
        ),
      ])
      .create(this.panel.nativeElement);

    this.player.play();
  }

  reset() {
    this.store.dispatch(new ResetThemeAll());
    // this.nkConfig.resetConfig();
  }

  updateColorClassesFooter(klass: string) {
    this.store.dispatch(new UpdateColorClassesFooter(klass));
  }

  updateColorClassesNavbar(klass: string) {
    this.store.dispatch(new UpdateColorClassesNavbar(klass));
  }

  updateColorClassesToolbar(klass: string) {
    this.store.dispatch(new UpdateColorClassesToolbar(klass));
  }

  updateCustomScrollbars(data) {
    this.store.dispatch(new UpdateCustomScrollbars(data));
  }

  updateLayoutFooter(position: 'below' | 'above' | 'none') {
    this.store.dispatch(new UpdateLayoutFooter(position));
  }

  updateLayoutMode(type: 'boxed' | 'fullwidth') {
    this.store.dispatch(new UpdateLayoutMode(type));
  }

  updateLayoutNavigation(position: NavigationType) {
    this.store.dispatch(new UpdateNavigationPosition(position));
  }

  updateLayoutTheme(theme: string) {
    this.store.dispatch(new UpdateLayoutTheme(theme));
  }

  updateLayoutToolbar(position: 'below' | 'above' | 'none') {
    this.store.dispatch(new UpdateLayoutToolbar(position));
  }

  updateRouterAnimation(data) {
    this.store.dispatch(new UpdateRouterAnimation(data));
  }
}
