<div
  fxLayout="row"
  fxLayoutGap="12px"
>
  <div>
    <h2>User Profile</h2>
  </div>
  <!-- <div>
    <mat-slide-toggle
      [checked]="useDefault"
      (change)="toggle($event)"
    >Slide me!</mat-slide-toggle>
  </div> -->
</div>
<form
  [formGroup]="updateProfileForm"
  (ngSubmit)="updateProfile()"
  novalidate
>
  <mat-dialog-content
    fxLayout="column"
    fxLayoutGap="8px"
  >
    <div
      matTooltip="Update Pic"
      class="text-center"
    >
      <label
        class="hoverable ma"
        for="fileInput"
      >
        <img
          [src]="url ? url : profileData.pic"
          style="object-fit: cover;"
          class="s128"
        >
        <div class="hover-text">
          <mat-icon>create</mat-icon>
        </div>
        <div class="background"></div>
      </label>
      <br />
      <input
        id="fileInput"
        type='file'
        (change)="updatePhoto($event)"
      >
      <!-- <button
        *ngIf="url"
        (click)="conform()"
      >update</button> -->
      <!-- <button
        mat-icon-button
        type="button"
       *ngIf="url"
      >
        <mat-icon>done</mat-icon>
      </button> -->
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input
        matInput
        placeholder="Name"
        formControlName="name"
        required='true'
      >
      <mat-error>Name should must be valid</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        matInput
        placeholder="Email"
        formControlName="email"
        required='true'
      >
      <mat-error>Email should must be valid</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>User Name</mat-label>
      <input
        matInput
        placeholder="User Name"
        formControlName="username"
        required='true'
      >
      <mat-icon matSuffix></mat-icon>
      <mat-error>
        should start from a alphabet<br />
        min 6 and max 12 characters allowed. eg:- abc_xyz123
      </mat-error>
    </mat-form-field>
    <div fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>Old Password</mat-label>
        <input
          matInput
          placeholder="Old Password"
          formControlName="oldPassword"
          #oldPassword
          [type]="toggleVisibility1.type"
          autocomplete="off"
        >
        <mat-pass-toggle-visibility
          #toggleVisibility1
          matSuffix
        ></mat-pass-toggle-visibility>
        <mat-error>
          should have min 8 and max 20 characters.
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input
          matInput
          placeholder="Password"
          formControlName="password"
          #password
          [type]="toggleVisibility2.type"
          autocomplete="off"
        >
        <mat-pass-toggle-visibility
          #toggleVisibility2
          matSuffix
        ></mat-pass-toggle-visibility>
        <mat-error>
          should have min 8 and max 20 characters.
        </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column">
      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          placeholder="Confirm Password"
          formControlName="confirmPassword"
          #confirmPassword
          [type]="toggleVisibility3.type"
          autocomplete="off"
        >
        <mat-pass-toggle-visibility
          #toggleVisibility3
          matSuffix
        ></mat-pass-toggle-visibility>
        <mat-error>
          this should be same as Password.
        </mat-error>
      </mat-form-field>
    </div>
    <mat-radio-group
      aria-label="Select an option"
      formControlName="gender"
      fxLayout="row"
      fxLayoutGap="12px"
      class="mb16"
    >
      <mat-radio-button value="male">Male</mat-radio-button>
      <mat-radio-button value="female">Female</mat-radio-button>
    </mat-radio-group>
    <mat-form-field appearance="outline">
      <mat-label>Mobile Number</mat-label>
      <input
        matInput
        placeholder="Mobile Number"
        formControlName="mobile"
      >
      <mat-error>Mobile Number should must be valid</mat-error>
    </mat-form-field>
    <mat-error *ngFor='let List of errorList'>
      {{List}}
    </mat-error>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-stroked-button
      [mat-dialog-close]='false'
    >Cancel</button>
    <button
      mat-stroked-button
      color="accent"
      [disabled]="updateProfileForm.invalid"
    >Update</button>
  </mat-dialog-actions>
</form>
<!-- <button
  style="float: right;"
  [mat-dialog-close]='false'
  mat-stroked-button
  color="accent"
  (click)="moreDetails(profileData.id)"
>More Details</button> -->
