export const APP_THEME = '[APP_THEME]';

// APP THEME
// -=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=--=-

export class UpdateLayoutTheme {
  static readonly type = APP_THEME + ' UpdateLayoutTheme';
  constructor(public theme: string) {}
}

export class ResetLayoutTheme {
  static readonly type = APP_THEME + ' ResetLayoutTheme';
  constructor() {}
}

export class ResetLayoutNavigation {
  static readonly type = APP_THEME + ' ResetLayoutNavigation';
  constructor() {}
}

export class UpdateLayoutToolbar {
  static readonly type = APP_THEME + ' UpdateLayoutToolbar';
  constructor(public position: 'below' | 'above' | 'none') {}
}

export class ResetLayoutToolbar {
  static readonly type = APP_THEME + ' ResetLayoutToolbar';
  constructor() {}
}

export class UpdateLayoutFooter {
  static readonly type = APP_THEME + ' UpdateLayoutFooter';
  constructor(public position: 'below' | 'above' | 'none') {}
}

export class ResetLayoutFooter {
  static readonly type = APP_THEME + ' ResetLayoutFooter';
  constructor() {}
}

export class UpdateLayoutMode {
  static readonly type = APP_THEME + ' UpdateLayoutMode';
  constructor(public type: 'boxed' | 'fullwidth') {}
}

export class ResetLayoutMode {
  static readonly type = APP_THEME + ' ResetLayoutMode';
  constructor() {}
}

export class UpdateColorClassesToolbar {
  static readonly type = APP_THEME + ' UpdateColorClassesToolbar';
  constructor(public klass: string) {}
}

export class ResetColorClassesToolbar {
  static readonly type = APP_THEME + ' ResetColorClassesToolbar';
  constructor() {}
}

export class UpdateColorClassesNavbar {
  static readonly type = APP_THEME + ' UpdateColorClassesNavbar';
  constructor(public klass: string) {}
}

export class ResetColorClassesNavbar {
  static readonly type = APP_THEME + ' ResetColorClassesNavbar';
  constructor() {}
}

export class UpdateColorClassesFooter {
  static readonly type = APP_THEME + ' UpdateColorClassesFooter';
  constructor(public klass: string) {}
}

export class ResetColorClassesFooter {
  static readonly type = APP_THEME + ' ResetColorClassesFooter';
  constructor() {}
}

export class UpdateCustomScrollbars {
  static readonly type = APP_THEME + ' UpdateCustomScrollbars';
  constructor(public payload: any) {}
}

export class ResetCustomScrollbars {
  static readonly type = APP_THEME + ' ResetCustomScrollbars';
  constructor() {}
}

export class UpdateRouterAnimation {
  static readonly type = APP_THEME + ' UpdateRouterAnimation';
  constructor(public payload: any) {}
}

export class ResetRouterAnimation {
  static readonly type = APP_THEME + ' ResetRouterAnimation';
  constructor() {}
}

export class ResetThemeAll {
  static readonly type = APP_THEME + ' ResetThemeAll';
  constructor() {}
}

export const UpdateAppTheme = [
  // UpdateColorClassesFooter,
  // UpdateColorClassesNavbar,
  // UpdateColorClassesToolbar,
  UpdateCustomScrollbars,
  // UpdateLayoutFooter,
  // UpdateLayoutMode,
  // UpdateLayoutNavigation,
  // UpdateLayoutNavigationFolded,
  // UpdateLayoutTheme,
  // UpdateLayoutToolbar,
  UpdateRouterAnimation,
];

export const ResetAppTheme = [
  ResetLayoutTheme,
  ResetLayoutNavigation,
  ResetLayoutToolbar,
  ResetLayoutFooter,
  ResetLayoutMode,
  ResetColorClassesToolbar,
  ResetColorClassesNavbar,
  ResetColorClassesFooter,
  ResetCustomScrollbars,
  ResetRouterAnimation,
];
