import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCaseToDash' })
export class CamelCaseToDashPipe implements PipeTransform {
  transform(value: string, args: any[] = []) {
    return value
      ? String(value).replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)
      : '';
  }
}

@Pipe({ name: 'dashToTitle' })
export class DashToTitle implements PipeTransform {
  transform(value: string, args: any[] = []) {
    return value
      .split('-')
      .map((v) => v[0].toUpperCase() + v.slice(1))
      .join(' ');
  }
}
