import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MainPanelActions } from '../../actions/app/main-panel.actions';
import {
  ResetAppTheme,
  ResetThemeAll,
  UpdateAppTheme,
  UpdateColorClassesFooter,
  UpdateColorClassesNavbar,
  UpdateColorClassesToolbar,
  UpdateLayoutFooter,
  UpdateLayoutMode,
  UpdateLayoutTheme,
  UpdateLayoutToolbar,
} from '../../actions/app/theme.action';
import { AppThemeConfig } from '../../configs/theme.config';
import { immerv } from '../../shared/common-packages/immer';
import { defaults, merge } from '../../shared/common-packages/lodash';

const defCon = AppThemeConfig;

export interface IMainPanelState {
  themeOptionPanelOpen: boolean;
}

export class AppThemeModel {
  static id = 'APP_THEME';
  appTheme: typeof AppThemeConfig;
  mainPanelState: IMainPanelState;
}

@State<AppThemeModel>({
  name: 'APP_THEME',
  defaults: {
    appTheme: defCon,
    mainPanelState: { themeOptionPanelOpen: false },
  },
})
@Injectable()
export class AppThemeStore {
  @Selector()
  static appThemeConfig(state: AppThemeModel) {
    return state.appTheme;
  }

  @Selector()
  static MainPanelState(state: AppThemeModel) {
    return state.mainPanelState;
  }

  @Action(UpdateAppTheme)
  UpdateTheme({ setState }: StateContext<AppThemeModel>, { payload }: any) {
    setState(immerv((s: AppThemeModel) => merge(s.appTheme, payload)));
  }

  @Action(UpdateLayoutTheme)
  UpdateLayoutTheme(
    { setState }: StateContext<AppThemeModel>,
    { theme }: UpdateLayoutTheme
  ) {
    setState(immerv((s: AppThemeModel) => (s.appTheme.layout.theme = theme)));
  }

  @Action(UpdateLayoutToolbar)
  UpdateLayoutToolbar(
    { setState }: StateContext<AppThemeModel>,
    { position }: UpdateLayoutToolbar
  ) {
    setState(
      immerv((s: AppThemeModel) => (s.appTheme.layout.toolbar = position))
    );
  }

  @Action(UpdateLayoutFooter)
  UpdateLayoutFooter(
    { setState }: StateContext<AppThemeModel>,
    { position }: UpdateLayoutFooter
  ) {
    setState(
      immerv((s: AppThemeModel) => (s.appTheme.layout.footer = position))
    );
  }
  @Action(UpdateLayoutMode)
  UpdateLayoutMode(
    { setState }: StateContext<AppThemeModel>,
    { type }: UpdateLayoutMode
  ) {
    setState(immerv((s: AppThemeModel) => (s.appTheme.layout.mode = type)));
  }
  @Action(UpdateColorClassesToolbar)
  UpdateColorClassesToolbar(
    { setState }: StateContext<AppThemeModel>,
    { klass }: UpdateColorClassesToolbar
  ) {
    setState(
      immerv((s: AppThemeModel) => (s.appTheme.colorClasses.toolbar = klass))
    );
  }
  @Action(UpdateColorClassesFooter)
  UpdateColorClassesFooter(
    { setState }: StateContext<AppThemeModel>,
    { klass }: UpdateColorClassesFooter
  ) {
    setState(
      immerv((s: AppThemeModel) => (s.appTheme.colorClasses.footer = klass))
    );
  }
  @Action(UpdateColorClassesNavbar)
  UpdateColorClassesNavbar(
    { setState }: StateContext<AppThemeModel>,
    { klass }: UpdateColorClassesNavbar
  ) {
    setState(
      immerv((s: AppThemeModel) => (s.appTheme.colorClasses.navbar = klass))
    );
  }

  @Action(ResetAppTheme)
  resetFilter({ setState }: StateContext<AppThemeModel>, { payload }: any) {
    setState(immerv((s) => defaults(merge(s.appTheme, payload), defCon)));
  }

  @Action(ResetThemeAll)
  ResetThemeAll({ setState }: StateContext<AppThemeModel>, { payload }: any) {
    setState(immerv((s) => merge(s.appTheme, defCon)));
  }

  @Action(MainPanelActions.ThemeOptionPanelOpen)
  ThemeOptionPanelOpen(
    { setState }: StateContext<AppThemeModel>,
    { open }: MainPanelActions.ThemeOptionPanelOpen
  ) {
    setState(
      immerv(
        (s: AppThemeModel) => (s.mainPanelState.themeOptionPanelOpen = open)
      )
    );
  }
}
