<h2 mat-dialog-title>Session Expire</h2>
<mat-dialog-content
  style="height:100%; max-height:60vh"
  fxLayout="row"
>
  <form
    fxLayout="column"
    style="width: 100%"
    fxLayoutAlign="center center"
    (submit)="submit(password.value)"
  >
    <h4>@{{user.username}}</h4>
    <mat-form-field
      appearance="outline"
      style="width:320px"
    >
      <mat-label>Password</mat-label>
      <input
        type="password"
        matInput
        #password
      />
    </mat-form-field>
    <button
      mat-raised-button
      color="accent"
      class="w200 h44"
    >Submit</button>
  </form>
</mat-dialog-content>
