import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { NkNavigationService } from '../components/navigation/navigation.service';
import { NkSidebarService } from '../components/sidebar/sidebar.service';
import { NkCopierService } from '../services/app-services/copier.service';
import { NkMatchMediaService } from '../services/app-services/match-media.service';
import { NkMatSidenavHelperService } from '../services/app-services/sidenav.service';
import { NkSplashScreenService } from '../services/app-services/splash-screen.service';
import { NkTranslationLoaderService } from '../services/app-services/translation-loader.service';

@NgModule({
  entryComponents: [],
  providers: [
    // NkConfigService,
    NkCopierService,
    NkMatchMediaService,
    NkMatSidenavHelperService,
    NkNavigationService,
    NkSidebarService,
    NkSplashScreenService,
    NkTranslationLoaderService,
  ],
})
export class NkModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: NkModule
  ) {
    if (parentModule) {
      throw new Error(
        'NkModule is already loaded. Import it in the AppModule only!'
      );
    }
  }

  static forRoot(config): ModuleWithProviders<NkModule> {
    return {
      ngModule: NkModule,
      providers: [
        /* { provide: NK_CONFIG, useValue: config } */
      ],
    };
  }
}
