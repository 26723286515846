import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class NkMatSidenavHelperService {
  private readonly sidenavInstances: { [key: string]: MatSidenav } = {};

  constructor() {}

  setSidenav(id: string, instance: MatSidenav) {
    this.sidenavInstances[id] = instance;
  }

  getSidenav(id: string) {
    return this.sidenavInstances[id];
  }
}
