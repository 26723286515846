export const environment = {
  production: true,
  debug: false,
  verbose: false,
  appName: 'GoSME',
  appUrl: 'gosme.biz',
  appKey: 'gosme.biz',
  appFullName: 'The Application',
  Domain: window.location.origin + '/',
  apiDomain: '/api',
  appEmail: 'it_expert@the_app.com',
  mail: {
    from: {
      name: 'President',
      address: 'president@the_app.com',
    },
  },
  aws: {
    AWS_URL: 'http://localhost:8602/cwd.dev/',
    bucket: 'cwd.dev',
    hasCDN: false,
  },
};
