import { Injectable } from '@angular/core';
import { Ability } from '@casl/ability';
import { ACLBuilder } from '@cwd/access-control/acl-builder';
import { genActiveUser } from '@cwd/access-control/active-user.model';
import { ModulesNames } from '@cwd/access-control/consts/modules.const';
import { Store } from '@ngxs/store';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject } from 'rxjs';
import { Domain } from '../configs/api.router.config';
import { DataBaseKeys } from '../configs/database.config';
import { Roles } from '../configs/roles-permission.config';
import { AddMenuGroup, ResetMenu } from '../stores/app/side-menu.state';
import { SnackBarService } from './app-services/snack-bar.service';

export const URL = {
  message: 'messenger',
  promotion: 'promotion',
  news: 'news',
  event: 'event',
  documents: 'document',
};

interface Menu {
  id: string;
  title: string;
  type: string;
  icon: string;
  exactMatch?: boolean;
  url: string;
}

const genPer = (acl: Ability<any>) => {
  const pages = {
    id: 'pages',
    title: 'Pages',
    translate: 'Pages',
    type: 'group',
    children: [] as Menu[],
  };
  const applications = {
    id: 'applications',
    title: 'Applications',
    translate: 'Applications',
    type: 'group',
    children: [] as Menu[],
  };
  const QuickLinks = {
    id: 'quickLinks',
    title: 'Quick Links',
    translate: 'Quick Links',
    type: 'group',
    children: [] as Menu[],
  };

  const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'network_check',
    exactMatch: true,
    url: '/',
  };
  // const group = {
  //   id: 'groups',
  //   title: 'Groups',
  //   type: 'item',
  //   icon: 'group',
  //   url: '/group',
  // };

  /*   const category = {
    id: 'categories',
    title: 'Categories',
    type: 'item',
    icon: 'category',
    url: '/category',
  }; */

  const domains = {
    id: 'domains',
    title: 'Domains',
    type: 'item',
    icon: 'category',
    url: '/domains',
  };

  const users = {
    id: 'users',
    title: 'Users',
    type: 'item',
    icon: 'supervised_user_circle',
    url: '/users',
  };

  const post = {
    id: 'post',
    title: 'Post',
    type: 'item',
    icon: 'post_add',
    url: '/post',
  };
  const comments = {
    id: 'comments',
    title: 'Comments',
    type: 'item',
    icon: 'comment',
    url: '/comments',
  };
  const reports = {
    id: 'Reports',
    title: 'Reports',
    type: 'item',
    icon: 'report',
    url: '/reports',
  };
  const payment = {
    id: 'Payment',
    title: 'Payment',
    type: 'item',
    icon: 'payment',
    url: '/paymentMode',
  };

  const slide = {
    id: 'slide',
    title: 'Slide',
    type: 'item',
    icon: 'supervised_user_circle',
    url: '/slide',
  };
  const chat = {
    id: 'chat',
    title: 'chat',
    type: 'item',
    icon: 'supervised_user_circle',
    url: '/chat',
  };

  pages.children.push(dashboard);
  if (acl.can('read', ModulesNames.USERS)) {
    pages.children.push(users);
  }
  if (acl.can('read', ModulesNames.DOMAINS)) {
    pages.children.push(domains);
  }
  if (acl.can('read', 'payment')) {
    pages.children.push(payment);
  }
  // if (acl.can('read', ModulesNames.CATS)) {
  //   pages.children.push(category);
  // }
  // if (acl.can('read', ModulesNames.GROUPS)) {
  //   pages.children.push(group);
  // }

  if (acl.can('read', ModulesNames.COMMENTS)) {
    applications.children.push(comments);
  }

  if (acl.can('read', ModulesNames.REPORTS)) {
    applications.children.push(reports);
  }

  if (acl.can('read', ModulesNames.POSTS)) {
    applications.children.push(post);
  }

  // if (acl.can('read', ModulesNames.SLIDE)) {
  //   applications.children.push(slide);
  // }
  if (acl.can('read', 'chat')) {
    applications.children.push(chat);
  }

  return [pages, applications];
};

export class AppAbility extends Ability {
  can(action: string, data: any[]) {
    if (typeof data === 'string') {
      return super.can(action, data);
    }
    return super.can(action, { ...data[1], constructor: { name: data[0] } });
  }
}

@Injectable({ providedIn: 'root' })
export class UserGlobalService {
  public UserData: any = null;
  // private MboStatusData = null;
  private _auth: any = null;
  // private adminPermission = [];
  // private _userPermission = [];
  public onUserLogin = new BehaviorSubject({});
  public onMBOStatus = new BehaviorSubject({});
  public acl!: Ability<any>;
  constructor(
    private readonly ability: AppAbility,
    private readonly clipboardService: ClipboardService,
    private readonly snackService: SnackBarService,
    private readonly store: Store
  ) {}

  public updatePermissions(userDetails: any) {
    const acl = ACLBuilder.build(genActiveUser(userDetails));
    this.ability.update(acl.rules);
    this.acl = acl;
    console.log(10 ** 10, this.ability);
    const menu = genPer(acl);
    this.store.dispatch(new ResetMenu());
    menu.forEach((v) => {
      this.store.dispatch(new AddMenuGroup(v));
    });
  }

  public copyText(page: string, id: string | number) {
    const url = Domain + page + '/' + id;
    this.clipboardService.copyFromContent(url);
    this.snackService.success('Url copied', 'Successful');
  }

  get user() {
    if (this.UserData) {
      return this.UserData;
    }
    if (localStorage.getItem(DataBaseKeys.User)) {
      this.UserData = JSON.parse(
        atob(localStorage.getItem(DataBaseKeys.User) || '')
      );
      this.onUserLogin.next(this.UserData);
    } else {
      this.UserData = null;
    }
    return this.UserData || {};
  }

  // get MboStatus() {
  //   if (this.MboStatusData) {
  //     return this.MboStatusData;
  //   }
  //   if (localStorage.getItem(DataBaseKeys.MBO)) {
  //     this.MboStatusData = JSON.parse(
  //       atob(localStorage.getItem(DataBaseKeys.MBO))
  //     );
  //     this.onMBOStatus.next(this.MboStatusData);
  //   } else {
  //     this.MboStatusData = null;
  //   }
  //   return this.MboStatusData || {};
  // }

  set user(val) {
    this.UserData = null;
    // this.MboStatusData = null;
    localStorage.setItem(DataBaseKeys.User, btoa(JSON.stringify(val)));
    this.UserData = { ...val };
    this.onUserLogin.next(this.UserData);
  }

  // set MboStatus(value) {
  //   localStorage.setItem(DataBaseKeys.MBO, btoa(JSON.stringify(value)));
  //   this.MboStatusData = { ...value };
  //   this.onMBOStatus.next(this.MboStatusData);
  // }

  // public MboId() {

  //   return this.MboStatus['mboId'] || null;
  // }

  // public MboGetStatus() {

  //   return this.MboStatus['status'] || null;
  // }

  public userRole() {
    // console.log(this.UserData['role']);
    return this.user['role'] || [];
  }
  public hasPermission(ar: string[]) {
    return !ar ? true : ar.some((v) => this.userRole().includes(v));
  }

  // public isUserLogin() {
  //   return this.userRole().length > 0;
  // }

  // public getUserId() {
  //   return this.user['id'] || null;
  // }

  // public getMembershipNo() {
  //   return this.user['memberShipNo'] || null;
  // }

  // public getUserName() {
  //   // console.log("user name ", this.user['username'] );
  //   return this.user['username'] || null;
  // }

  // public getUserProfile() {
  //   return this.user['profile_image'] || null;
  // }

  // private userPermission() {
  //   if (this.isAdmin()) {
  //     return this.adminPermission;
  //   } else if (this.isMember()) {
  //     return this._userPermission;
  //   }
  // }

  // public hasPermission(ar) {
  //   return !ar ? true : ar.some((v) => this.userRole().includes(v));
  // }

  // private leadingGroups() {
  //   return this.user.leading_groups || [];
  // }

  // private isLeadingGroup(groups) {
  //   return this.user.leading_groups.some((v) => groups.includes(v.name));
  // }

  public isAdmin() {
    return this.userRole().includes(Roles.admin);
  }

  public isSuperAdmin() {
    return this.userRole().includes(Roles.super_admin);
  }

  public isMember() {
    return this.userRole().includes(Roles.member);
  }

  // public isMBOEditor() {
  //   return this.userRole().includes(Roles.mbo_editor);
  // }

  public isUserRole(role: string) {
    return this.userRole().includes(role);
  }

  // public getRank() {
  //   return this.user['rank'] || null;
  // }

  get auth() {
    if (this._auth) {
      return this._auth;
    }
    if (localStorage.getItem(DataBaseKeys.Token)) {
      this._auth = JSON.parse(
        atob(localStorage.getItem(DataBaseKeys.Token) || '')
      );
    } else {
      this._auth = null;
    }
    return this._auth || {};
  }

  set auth(val) {
    localStorage.setItem(DataBaseKeys.Token, btoa(JSON.stringify(val)));
    this._auth = { ...val };
  }
}
