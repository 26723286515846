import sift from 'sift';
export class ActiveUser {
  id: number;
  name: string;
  username: string;
  email: string;
  mobile: string;
  gender: string;
  pic: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  roles: any[];
  perms: any[];
  orgs: any[];
  groups: any[];
  meta: any;

  #orgsOwned: any[];

  toJSON() {
    return this;
  }

  hasRole(role: string) {
    return this.roles.find((v) => v.name === role);
  }

  hasRoleWithCtx(role: string, ctx: any) {
    const r = this.roles.find((v) => v.name === role);
    return r && sift(r.userRoleMeta.ctx)(ctx);
  }

  hasAnyRole(roles: string[]) {
    return this.roles.some((v) => roles.includes(v.name));
  }

  orgsOwned() {
    if (!this.#orgsOwned) {
      this.#orgsOwned = this.orgs.filter((v) => v.owner === this.id);
    }
    return this.#orgsOwned;
  }

  isOwnerOf(org: number) {
    return this.orgsOwned().some((v) => v.id === org);
  }

  isMemberOfOrg(org: number) {
    return this.orgs.some((v) => v.id === org);
  }
}

export const genActiveUser = (userDetails) => {
  const activeUser = new ActiveUser();
  activeUser.id = userDetails.id;
  activeUser.name = userDetails.name;
  activeUser.username = userDetails.username;
  activeUser.email = userDetails.email;
  activeUser.mobile = userDetails.mobile;
  activeUser.gender = userDetails.gender;
  activeUser.pic = userDetails.pic;
  activeUser.isActive = userDetails.isActive;
  activeUser.createdAt = userDetails.createdAt;
  activeUser.updatedAt = userDetails.updatedAt;
  activeUser.roles = (userDetails.roles || []).map((role) => ({
    id: role.id,
    name: role.name || role.role,
    userRoleMeta: JSON.parse(role.user_role_meta ?? '{}'),
  }));
  activeUser.perms = userDetails.perms;
  activeUser.orgs = userDetails.orgs;
  activeUser.groups = userDetails.groups;
  activeUser.meta = userDetails.meta;
  return activeUser;
};
