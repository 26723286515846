import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NkMatchMediaService {
  // private activeMediaQuery: string;
  public readonly onMediaChange: BehaviorSubject<
    MediaChange[]
  > = new BehaviorSubject<MediaChange[]>([]);

  constructor(public readonly observableMedia: MediaObserver) {
    // this.activeMediaQuery = '';

    this.observableMedia.asObservable().subscribe((changes: MediaChange[]) => {
      // const change = changes[0];

      // if (this.activeMediaQuery !== change.mqAlias) {
      // this.activeMediaQuery = change.mqAlias;
      this.onMediaChange.next(changes);
      // }
    });
  }
}
