import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MultipleFileService {
  public readonly uploadQues$ = new Subject<[string, File, () => {}][]>();
  public readonly uploadQues: {
    url: string;
    file: File;
    upload: number;
  }[] = [];

  constructor(
    private readonly http: HttpClient // private readonly dialogService: MatDialog
  ) {
    // this.uploadQues$.subscribe((v) => {
    //   const data = v.map((a) => ({ url: a[0], file: a[1], upload: 0 }));
    //   this.uploadQues.push(...data);
    // });
  }

  upload(url: string, file: File) {
    // console.log(file);
    // const formData: FormData = new FormData();

    // formData.append('thumb', file);

    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
      responseType: 'text',
    });

    return this.http.request(req).pipe(delay(100));
  }
  // return this.http.post(Routes.pre, formData, {
  //
  // });
  // const req = new HttpRequest('POST', `${Router}/pre`, formData, {
  //   reportProgress: true,
  //  ,
  // });

  //return this.http.request(req);

  getFiles(): Observable<any> {
    return this.http.get(`/files`);
  }

  uploadFile(x: any) {
    return new Promise((r) => this.uploadQues$.next([[...x, r]] as any));
  }
}
