import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../pages/auth/auth.service';
import { UserGlobalService } from '../services/user-global.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly userGlobalService: UserGlobalService,
    private readonly authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/api/')) {
      const accessToken = this.userGlobalService.auth.accessToken;
      let requestHeaders = req.headers;
      if (accessToken) {
        requestHeaders = requestHeaders.set(
          'Authorization',
          `Bearer ${accessToken}`
        );
        if (requestHeaders.has('Accept')) {
          requestHeaders = requestHeaders.set('Accept', `application/json`);
        }
        if (requestHeaders.has('Content-Type')) {
          requestHeaders = requestHeaders.set(
            'Content-Type',
            `application/json`
          );
        }
      }
      const authReq = req.clone({ headers: requestHeaders });
      return next.handle(authReq).pipe(catchError((e) => this.onError(e)));
    }
    return next.handle(req);
  }

  onError(err) {
    console.log(err);
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401) {
        if (err.error.message === 'TokenExpiredError') {
          console.log('Token Expire' + JSON.stringify(err));
          this.authService.sessionExpire();
        }
      } else if (err.status === 0) {
      } else {
        console.log('Request Error' + JSON.stringify(err));
      }
    }

    return throwError(err);
  }
}
