<h2 mat-dialog-title>Enter OTP</h2>
<form
  [formGroup]="tokenForm"
  fxLayout="column"
  novalidate
>
  <div fxLayout="column">
    <mat-form-field>
      <input
        matInput
        type="password"
        placeholder="Token"
        formControlName="token"
      />
      <mat-error>8 digit Token is required</mat-error>
    </mat-form-field>
  </div>
  <div
    fxLayout="row"
    class='mt12'
    fxLayoutGap="20px"
  >
    <button
      mat-raised-button
      color="accent"
      type="button"
      class="submit-button"
      [mat-dialog-close]="tokenForm.value"
      [disabled]="tokenForm.invalid"
    >SUBMIT</button>
    <button
      mat-stroked-button
      [mat-dialog-close]="{canceled:true}"
    >CANCEL</button>
  </div>
</form>
