declare const require: any;

export const cloneDeep = require('lodash/cloneDeep');
export const countBy = require('lodash/countBy');
export const curry = require('lodash/curry');
export const defaults = require('lodash/defaults');
export const defaultsDeep = require('lodash/defaultsDeep');
export const filter = require('lodash/filter');
export const find = require('lodash/find');
export const flatMap = require('lodash/flatMap');
export const flatten = require('lodash/flatten');
export const flow = require('lodash/flow');
export const get = require('lodash/get');
export const groupBy = require('lodash/groupBy');
export const isNil = require('lodash/isNil');
export const keys = require('lodash/keys');
export const map = require('lodash/map');
export const merge = require('lodash/merge');
export const omit = require('lodash/omit');
export const pick = require('lodash/pick');
export const remove = require('lodash/remove');
export const sampleSize = require('lodash/sampleSize');
export const template = require('lodash/template');
export const times = require('lodash/times');
export const uniq = require('lodash/uniq');
export const uniqBy = require('lodash/uniqBy');
export const values = require('lodash/values');
export const without = require('lodash/without');
