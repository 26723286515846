export const Roles = {
  admin: 'admin',
  editor: 'editor',
  mbo_editor: 'mbo_editor',
  member: 'member',
  super_admin: 'super_admin',
  treasurer: 'treasurer',
  secretary: 'secretary',
};

const highPrivilege = [Roles.super_admin, Roles.admin, Roles.editor];
const all = [Roles.super_admin, Roles.admin, Roles.member, Roles.editor];

export const UIPermission = {
  Slider: {
    canAddFolderOrFiles: highPrivilege,
    canEdit: highPrivilege,
    canView: all,
    canDelete: highPrivilege,
  },
  Documents: {
    canAddFolderOrFiles: highPrivilege,
    canEdit: highPrivilege,
    canView: all,
    canDelete: highPrivilege,
  },

  Messengers: {
    canAdd: highPrivilege,
    canEdit: highPrivilege,
    canView: all,
    canDelete: highPrivilege,
  },

  News: {
    canAdd: highPrivilege,
    canEdit: highPrivilege,
    canView: all,
    canDelete: highPrivilege,
  },

  Promotion: {
    canAdd: highPrivilege,
    canEdit: highPrivilege,
    canView: all,
    canDelete: highPrivilege,
  },

  Report: {
    add: all,
    edit: all,
    view: all,
    delete: highPrivilege,
    viewCat: highPrivilege,
  },

  EClaim: {
    add: all,
    edit: all,
    view: all,
    delete: highPrivilege,
    viewCat: highPrivilege,
  },

  Info: {
    add: highPrivilege,
    edit: highPrivilege,
    view: all,
    delete: highPrivilege,
    viewCat: highPrivilege,
  },

  // Airoster: {
  //   view: highPrivilege,
  // },

  forum: {
    catAdd: highPrivilege,
    catEdit: highPrivilege,
    catDelete: highPrivilege,
    add: all,
    delete: highPrivilege,
    edit: highPrivilege,
    CommentEdit: highPrivilege,
  },

  event: {
    add: highPrivilege,
    edit: highPrivilege,
    delete: highPrivilege,
    usersList: highPrivilege,
    view: all,
  },
  Survey: {
    view: [Roles.member, Roles.admin, Roles.super_admin],
    add: [Roles.admin, Roles.super_admin],
    delete: [Roles.admin, Roles.super_admin],
    edit: [Roles.admin, Roles.super_admin],
    joinSurvey: [Roles.member, Roles.admin, Roles.super_admin],
    surveyData: [Roles.super_admin],
  },
  /*   product: {
    shopDetails: [Roles.super_admin, Roles.admin, Roles.editor],
    shopEdit: [Roles.super_admin, Roles.admin],
  },
  deliveryMethod: {
    viewDeliveryMethod: [Roles.editor, Roles.super_admin],
  }, */

  canEvent: [Roles.super_admin, Roles.admin, Roles.member],
  canReport: [Roles.super_admin, Roles.admin, Roles.member],
  canSeeReportCategory: [Roles.super_admin, Roles.admin, Roles.editor],
  canSeeMemberList: [Roles.admin],
  canSeeMBODashBoard: [Roles.admin, Roles.mbo_editor, Roles.member],
  canSeeUserList: [Roles.admin],
  canSeeGroup: [Roles.admin],
  canSeeCategory: [Roles.admin],
  canSeeMBO: [Roles.admin, Roles.mbo_editor],

  canApplyMBO: [Roles.member],
  canViewMboOur: [Roles.member],
  canSeeDashboard: [Roles.member, Roles.admin, Roles.super_admin],
  canSeeAdminList: [Roles.super_admin],
  canSeeUserProfile: [Roles.member],
  canSeeNews: [Roles.member, Roles.admin, Roles.super_admin],
  canSeePromotion: [Roles.member, Roles.admin, Roles.super_admin],
};

export abstract class Permission {
  abstract userService;
  abstract PermModule;

  can(perm) {
    return this.userService.hasPermission(this.PermModule[perm]);
  }
}
