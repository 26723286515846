<div fxLayout="column">
  <h2>Uploading Files</h2>
  <mat-divider></mat-divider>
  <div
    fxLayout="column"
    fxLayoutGap="8px"
  >
    <div *ngFor="let item of uploadQues">
      <div class="p4">{{item.file.name}}</div>
      <!-- <div>{{item.upload}}%</div> -->
      <mat-progress-bar
        *ngIf="item.upload"
        color="primary"
        mode="determinate"
        [value]="item.upload"
      ></mat-progress-bar>
    </div>
  </div>
</div>
