export const AppThemeConfig = {
  layout: {
    theme: 'default', // 'default', 'black', 'light', 'nature'
    navigation: 'left', // 'right', 'left', 'top', 'none'
    toolbar: 'below', // 'above', 'below', 'none'
    footer: 'none', // 'above', 'below', 'none'
    mode: 'fullwidth', // 'boxed', 'fullwidth'
  },
  colorClasses: {
    toolbar: 'mat-primary-bg',
    navbar: 'mat-nk-dark-700-bg',
    footer: 'mat-nk-dark-900-bg',
  },
  customScrollbars: true,
  routerAnimation: 'fadeIn', // fadeIn, slideUp, slideDown, slideRight, slideLeft, none
};

export const AllThemes = {
  default: {
    klass: '',
    name: 'Default',
    theme: { primary: 'blue-grey', accent: 'orange', warn: 'red' },
  },
  black: {
    klass: '_0',
    name: 'Black',
    theme: { primary: 'pink', accent: 'blue-grey', warn: 'red' },
  },
  /*   light: {
    klass: '_1',
    name: 'Light',
    theme: { primary: 'deep-purple', accent: 'amber', warn: 'red' },
  },
  dark: {
    klass: '_2',
    name: 'Dark',
    theme: { primary: 'purple', accent: 'green', warn: 'red' },
  }, */
};
