export const wait = (x: number) => new Promise((s) => setTimeout(s, x, x));
export const noop = (...x) => {};
// import nw from '@nk/nw';

export const SortAlpha = (order) => (a, b) =>
  a.title > b.title ? order * 1 : a.title === b.title ? 0 : order * -1;

// export const numberToWord = nw;

// export const inWord = n => {
//   const [d, c] = n
//     .toString()
//     .split('.')
//     .map(nw);
//   return `${d} Dollar ${c ? 'and ' + c + ' Cent' : ''}`;
// };

export const hasCommon = (a: any[], b: any[]) => a.some((v) => b.includes(v));
export const pluck = (a, k) => a.map((v) => v[k]);
