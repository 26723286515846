export const ShortcutsItems = [
  // {
  //   title: 'sample',
  //   type: 'nav-item',
  //   icon: 'today',
  //   url: '/sample'
  // },
  // {
  //   title: 'Mail',
  //   type: 'nav-item',
  //   icon: 'email',
  //   url: '/apps/mail'
  // },
  // {
  //   title: 'Contacts',
  //   type: 'nav-item',
  //   icon: 'account_box',
  //   url: '/apps/contacts'
  // },
  // {
  //   title: 'To-Do',
  //   type: 'nav-item',
  //   icon: 'check_box',
  //   url: '/apps/todo'
  // }
];
