import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style,
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { of } from 'rxjs';
import { delay, filter, take, tap } from 'rxjs/operators';
import { wait } from '../../shared/fun';

@Injectable()
export class NkSplashScreenService {
  private splashScreenEl!: HTMLElement | null;
  public player!: AnimationPlayer;

  constructor(
    private readonly animationBuilder: AnimationBuilder,
    @Inject(DOCUMENT) private readonly document: HTMLDocument,
    private readonly router: Router
  ) {}

  public init() {
    this.splashScreenEl = this.document.body.querySelector<HTMLElement>('#nk');

    if (this.splashScreenEl) {
      return this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1),
        delay(10),
        tap(() => this.hide())
      );
    }
    return of(null);
  }

  public async show() {
    this.player = this.animationBuilder
      .build([
        style({ opacity: '0', zIndex: '99999' }),
        animate('400ms ease', style({ opacity: '1' })),
      ])
      .create(this.splashScreenEl);

    await wait(1);
    this.player.play();
  }

  public async hide() {
    this.player = this.animationBuilder
      .build([
        style({ opacity: '1' }),
        animate('400ms ease', style({ opacity: '0', zIndex: '-10' })),
      ])
      .create(this.splashScreenEl);

    await wait(1);
    this.player.play();
  }
}
