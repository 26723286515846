import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nk-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  // animations: nkAnimations
})
export class NkContentComponent implements OnDestroy {
  /*   onConfigChangedSubscription: Subscription;
  nkSettings: any;
  routerEventsSubscription: Subscription;

  @HostBinding('@routerTransitionUp')
  routeAnimationUp = false;
  @HostBinding('@routerTransitionDown')
  routeAnimationDown = false;
  @HostBinding('@routerTransitionRight')
  routeAnimationRight = false;
  @HostBinding('@routerTransitionLeft')
  routeAnimationLeft = false;
  @HostBinding('@routerTransitionFade')
  routeAnimationFade = false;
 */
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    /*  this.routerEventsSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.nkSettings && this.nkSettings.routerAnimation) {
          switch (this.nkSettings.routerAnimation) {
            case 'fadeIn':
              this.routeAnimationFade = !this.routeAnimationFade;
              break;
            case 'slideUp':
              this.routeAnimationUp = !this.routeAnimationUp;
              break;
            case 'slideDown':
              this.routeAnimationDown = !this.routeAnimationDown;
              break;
            case 'slideRight':
              this.routeAnimationRight = !this.routeAnimationRight;
              break;
            case 'slideLeft':
              this.routeAnimationLeft = !this.routeAnimationLeft;
              break;
          }
        }
      });

    this.onConfigChangedSubscription = this.nkConfig.onConfigChanged.subscribe(
      newSettings => {
        this.nkSettings = newSettings;
      }
    ); */
  }

  ngOnDestroy() {
    /*     if (this.onConfigChangedSubscription) {
      this.onConfigChangedSubscription.unsubscribe();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    } */
  }
}
