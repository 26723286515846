import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { NkSearchBarModule, NkShortcutsModule } from '../../../../components';
import { UserProfileModule } from '../../../../modals/user-profile/user-profile.module';
import { NkSharedModule } from '../../../../shared/shared.module';
import { NkToolbarComponent } from './toolbar.component';
@NgModule({
  declarations: [NkToolbarComponent],
  imports: [
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    NkSearchBarModule,
    NkSharedModule,
    NkShortcutsModule,
    ReactiveFormsModule,
    RouterModule,
    UserProfileModule,
  ],
  exports: [NkToolbarComponent],
  entryComponents: [],
})
export class NkToolbarModule {}
