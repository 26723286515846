import { environment } from '../../environments/environment.prod';

export const ApiDomain = environment.apiDomain;
export const Domain = environment.Domain;
export const ProjectUrl = ApiDomain;
// export const DEFAULT_HEADERS = {
//   Accept: 'application/json',
//   'Content-Type': 'application/json',
// };
export const urlResolver = (url: string) => (obj: object) => {
  return Object.keys(obj).reduce((a, b) => a.replace(`{${b}}`, obj[b]), url);
};

export class Routes {
  static Password = ApiDomain + '/password';
  public static SetPassword = ApiDomain + '/auth/reset';
  public static SetEmail = ApiDomain + '/auth/updateEmail';
  public static paymentMode = ApiDomain + '/auth/app/PaymentMode';
  public static VerifyEmail = ApiDomain + '/auth/forgetPassword';
  public static VerifyEmailId = ApiDomain + '/auth/verifyEmail';
  public static getPaymentMode = ApiDomain + '/auth/getPaymentMode';
  public static sendToken = ApiDomain + '/auth/token';
  public static verifyEmailURL = urlResolver(
    ApiDomain + '/verify_member_email/{Id}'
  );

  public static Login = ApiDomain + '/auth/login';
  public static userLoginDetails = ApiDomain + '/auth/profile';

  public static getUserRoles = ApiDomain + '/users/get/roles';
  public static Registration = ApiDomain + '/users';
  public static createByAdmin = ApiDomain + '/users/createByAdmin';
  public static fbRegistration = ApiDomain + '/auth/fb';
  public static getUserList = ApiDomain + '/users';
  public static getReview = urlResolver(ApiDomain + '/users/{id}/review');
  public static getActivity = urlResolver(ApiDomain + '/users/{id}/activity');
  public static UpdatePic = urlResolver(ApiDomain + '/users/{id}/upPic');
  public static updateProfile = urlResolver(ApiDomain + '/users/{id}/update');

  public static updateUserProfile = urlResolver(
    ApiDomain + '/auth/{id}/updateUserProfile'
  );
  public static updateSubscribe = urlResolver(ApiDomain + '/users/{id}/updateSubscribe');
  public static updateRole = urlResolver(ApiDomain + '/users/{id}/updateRole');
  public static getRoleId = urlResolver(ApiDomain + '/users/{id}/roleId');
  // public static updatePic = urlResolver(ApiDomain + '/users/{id}/roleId');
  public static getRoles = ApiDomain + '/users/roles';
  public static createReview = ApiDomain + '/users/reviews';
  public static getSingleUser = urlResolver(
    ApiDomain + '/users/{id}/singleUser'
  );

  public static getAboutUser = urlResolver(ApiDomain + '/users/{id}/profile');
  public static getSingleUserProfile = ApiDomain + '/auth/profile';
  public static searchUser = ApiDomain + '/users/search/name';
  public static DeleteUser = urlResolver(ApiDomain + '/users/{id}/delete');
  // ----------------------------------------------------------------------------------------------------
  // CWD - Chats
  // ----------------------------------------------------------------------------------------------------
  public static getRecentChats = ApiDomain + '/chats/recent';

  // ----------------------------------------------------------------------------------------------------
  // CWD - Messengers
  // ----------------------------------------------------------------------------------------------------
  public static Messenger = ApiDomain + '/messenger';
  public static pre = ApiDomain + '/messenger/MultiUploadFile';
  public static CreateMessage = ApiDomain + '/messenger/create';
  public static DeleteMessage = urlResolver(
    ApiDomain + '/messenger/{id}/delete'
  );
  public static groupMessage = urlResolver(ApiDomain + '/messenger/{id}/group');
  public static updatePic = urlResolver(
    ApiDomain + '/messenger/{id}/uploadMessengerThumb'
  );
  public static updatePic2 = urlResolver(ApiDomain + '/messenger/{id}/pre');
  public static catMessage = urlResolver(ApiDomain + '/messenger/{id}/cat');
  public static UpdateMessage = urlResolver(
    ApiDomain + '/messenger/{id}/update'
  );
  public static assignGroup = urlResolver(
    ApiDomain + '/messenger/{id}/messengerGroup'
  );
  public static GetSingleMessage = urlResolver(ApiDomain + '/messenger/{id}');
  public static getGroup = ApiDomain + '/group/allgroup';
  public static getDomains = ApiDomain + '/domains/allDomains';
  public static likeMessage = urlResolver(
    ApiDomain + '/likeDislike/{id}/create_likeDislike'
  );
  // public LikeMessage = urlResolver(this.Messenger + '/{id}/like');

  // ----------------------------------------------------------------------------------------------------
  // CWD - Groups
  // ----------------------------------------------------------------------------------------------------

  public static GroupList = ApiDomain + '/group';
  public static CreateGroup = ApiDomain + '/group/create';
  public static DeleteGroup = urlResolver(ApiDomain + '/group/{id}/delete');
  // public static getGroup = ApiDomain + '/group/allgroup';
  // public getReadableGroupList = this.GroupList + '/readable_groups';
  // public getWritableGroupList = this.GroupList + '/writable_groups';
  // public combinedGroupData = this.GroupList + '/combined_group_data';
  // public GroupUserList = urlResolver(this.GroupList + '/users/{Id}');
  // public GroupView = urlResolver(this.GroupList + '/{Id}');
  public static updateGroup = urlResolver(ApiDomain + '/group/{id}/update');
  // ----------------------------------------------------------------------------------------------------
  // CWD - slide updateSlidePic
  // ----------------------------------------------------------------------------------------------------
  public static slide = ApiDomain + '/slide';
  public static CreateSlide = ApiDomain + '/slide/create_slide';
  public static updateSlidePic = urlResolver(
    ApiDomain + '/slide/{id}/uploadSlideThumb'
  );

  public static deleteSlide = urlResolver(
    ApiDomain + '/slide/{id}/deleteSlide'
  );
  public static groupSlide = urlResolver(ApiDomain + '/slide/{id}/group');
  public static catSlide = urlResolver(ApiDomain + '/slide/{id}/cat');
  // public static updateSlidePic = urlResolver(
  //   ApiDomain + '/slide/{id}/uploadSlideThumb'
  // );
  // public static catMessage = urlResolver(ApiDomain + '/messenger/{id}/cat');
  public static updateSlide = urlResolver(ApiDomain + '/slide/{id}/update');

  // ----------------------------------------------------------------------------------------------------
  // CWD - Categories
  // ----------------------------------------------------------------------------------------------------
  public static CatList = ApiDomain + '/cat';
  public static CreateCat = ApiDomain + '/cat/create_cat';
  // public Cat = urlResolver(this.CatList + '/{Id}');
  // public getCategoryList = this.CatList + '/short_cat';
  public static DeleteCat = urlResolver(ApiDomain + '/cat/{id}/deleteCat');
  public static updateCat = urlResolver(ApiDomain + '/cat/{id}/update_cat');

  // ----------------------------------------------------------------------------------------------------
  // CWD - Domains
  // ----------------------------------------------------------------------------------------------------
  public static domainList = ApiDomain + '/domains';
  public static createDomain = ApiDomain + '/domains/create';
  public static deleteDomain = urlResolver(ApiDomain + '/domains/{id}/delete');
  public static updateDomain = urlResolver(ApiDomain + '/domains/{id}/update');
  public static thumbDomain = urlResolver(ApiDomain + '/domains/{id}/pre');
  // ----------------------------------------------------------------------------------------------------
  // CWD - News
  // ----------------------------------------------------------------------------------------------------
  public static news = ApiDomain + '/news';
  public static createNews = ApiDomain + '/news/create_news';
  public static updatePicNews = urlResolver(
    ApiDomain + '/news/{id}/uploadNewsThumb'
  );

  public static deleteNews = urlResolver(ApiDomain + '/news/{id}/deleteNews');
  public static getSingleNews = urlResolver(ApiDomain + '/news/{id}/edit');
  public static UpdateNews = urlResolver(ApiDomain + '/news/{id}/update');
  public static groupNews = urlResolver(ApiDomain + '/news/{id}/group');
  public static catNews = urlResolver(ApiDomain + '/news/{id}/cat');
  public static likeNews = urlResolver(
    ApiDomain + '/likeDislike/{id}/create_likeDislike'
  );

  // ----------------------------------------------------------------------------------------------------
  // CWD - Promotion
  // ----------------------------------------------------------------------------------------------------
  public static promotion = ApiDomain + '/promotion';
  public static updatePromotionPic = urlResolver(
    ApiDomain + '/promotion/{id}/uploadPromotionThumb'
  );
  // public static catMessage = urlResolver(ApiDomain + '/messenger/{id}/cat');
  public static updatePromotion = urlResolver(
    ApiDomain + '/promotion/{id}/update'
  );

  public static CreatePromotion = ApiDomain + '/promotion/create_promotion';
  public static DeletePromotion = urlResolver(
    ApiDomain + '/promotion/{id}/deletePromotion'
  );
  public static GetSinglePromotion = urlResolver(ApiDomain + '/promotion/{id}');
  public static groupPromo = urlResolver(ApiDomain + '/promotion/{id}/group');
  public static catPromo = urlResolver(ApiDomain + '/promotion/{id}/cat');
  public static likePromo = urlResolver(
    ApiDomain + '/likeDislike/{id}/create_likeDislike'
  );

  // ----------------------------------------------------------------------------------------------------
  // CWD - Event
  // ----------------------------------------------------------------------------------------------------
  public static Event = ApiDomain + '/event';
  public static EventByMonth = ApiDomain + '/event/event_by_month';
  public static createEvent = ApiDomain + '/event/create_event';
  public static UpdateEvent = urlResolver(ApiDomain + '/event/{id}/update');
  public static deleteEvent = urlResolver(
    ApiDomain + '/event/{id}/deleteEvent'
  );
  public static GetSingleEvent = urlResolver(ApiDomain + '/event/{id}');
  public static multipleEventPic = urlResolver(
    ApiDomain + '/event/{id}/MultiUploadFile'
  );
  public static fileEvent = urlResolver(ApiDomain + '/event/{id}/file');
  // -----------------------------------------------------------------------------------
  // CWD - Reactions
  // -----------------------------------------------------------------------------------
  public static React = ApiDomain + '/reaction/react';
  public static updateEventPic = urlResolver(
    ApiDomain + '/event/{id}/uploadEventThumb'
  );
  public static closeEvent = urlResolver(ApiDomain + '/event/{id}/closeEvent');
  public static groupEvent = urlResolver(ApiDomain + '/event/{id}/group');
  public static catEvent = urlResolver(ApiDomain + '/event/{id}/cat');
  public static updateEventPic2 = urlResolver(ApiDomain + '/event/{id}/pre');
  public static uploadEventDocs = urlResolver(
    ApiDomain + '/event/{id}/doc/pre'
  );

  public static deleteEventDocs = urlResolver(
    ApiDomain + '/event/{id}/deleteFiles'
  );

  public static deleteFiles = urlResolver(
    ApiDomain + '/event/{id}/deleteFiles'
  );

  // ----------------------------------------------------------------------------------------------------
  // CWD - Documents
  // ----------------------------------------------------------------------------------------------------
  public static Document = ApiDomain + '/docFolder';
  // public static AddFiles = urlResolver(
  //   ApiDomain + '/docFolder/add_files/{parent_id}'
  // );

  public static createFolder = urlResolver(
    ApiDomain + '/docFolder/{id}/createFolder'
  );

  public static getDocumentData = urlResolver(
    ApiDomain + '/docFolder/{parent_id}'
  );

  public static SearchDocumentData = urlResolver(
    ApiDomain + '/docFolder/search_files/{searchText}'
  );

  public static groupDocument = urlResolver(
    ApiDomain + '/docFolder/{id}/group'
  );

  public static DeleteDocFolder = urlResolver(
    ApiDomain + '/docFolder/{id}/delete'
  );
  public static UpdateFolder = urlResolver(
    ApiDomain + '/docFolder/{id}/updateFolder'
  );

  public static documentUpload = urlResolver(ApiDomain + '/docFolder/{id}/pre');

  public static UpdateBatch = ApiDomain + '/docFolder/updateBatch';

  // ----------------------------------------------------------------------------------------------------
  // CWD - DocFile
  // ----------------------------------------------------------------------------------------------------
  // public static Document = ApiDomain + '/docFolder';
  // public static AddFiles = urlResolver(
  //   ApiDomain + '/docFolder/add_files/{parent_id}'
  // );
  public static createFile = urlResolver(
    ApiDomain + '/docFile/{id}/create_document_file'
  );

  // ----------------------------------------------------------------------------------------------------
  // CWD - post
  // ----------------------------------------------------------------------------------------------------

  public static post = ApiDomain + '/post';
  public static allComments = ApiDomain + '/post/allComments';
  public static allReport = ApiDomain + '/users/report/get/all';

  public static createPost = ApiDomain + '/post/create';

  public static updatePicPost = urlResolver(ApiDomain + '/post/{id}/pre');
  public static uploadPostDocs = urlResolver(ApiDomain + '/post/{id}/pre-bulk');
  public static getSinglePost = urlResolver(ApiDomain + '/post/{id}/view');
  public static UpdatePost = urlResolver(ApiDomain + '/post/{id}/update');
  public static getComment = urlResolver(ApiDomain + '/post/{id}/comments');
  public static searchTag = ApiDomain + '/post/tags';
  public static getProTag = ApiDomain + '/post/tags';
  public static deletePost = urlResolver(ApiDomain + '/post/{id}/delete');

  //-------------------------------------------------------------
  //Comment
  //-------------------------------------------------------------

  public static createComment = urlResolver(
    ApiDomain + '/post/{id}/comments/add'
  );
  public static updateComment = urlResolver(
    ApiDomain + '/post/{id}/comments/{cId}/update'
  );
  public static deleteComment = urlResolver(
    ApiDomain + '/post/{id}/comments/{cId}/delete'
  );

  public static v2f = ApiDomain + '/sso/wal/v2f';
}
