import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AppAnimations } from '../../../../animations';

@Component({
  selector: 'nk-nav-horizontal-collapse',
  templateUrl: './nav-horizontal-collapse.component.html',
  styleUrls: ['./nav-horizontal-collapse.component.scss'],
  animations: AppAnimations,
})
export class NkNavHorizontalCollapseComponent implements OnDestroy {
  onConfigChanged: Subscription;
  // nkSettings = { colorClasses: { navbar: 'mat-red-bg' } };
  isOpen = false;

  @HostBinding('class') classes = 'nav-item nav-collapse';
  @Input() item: any;

  @HostListener('mouseenter')
  open() {
    this.isOpen = true;
  }

  @HostListener('mouseleave')
  close() {
    this.isOpen = false;
  }

  constructor(/* private nkConfig: NkConfigService */) {
    // this.onConfigChanged = this.nkConfig.onConfigChanged.subscribe(
    //   (newSettings) => {
    //     this.nkSettings = newSettings;
    //   }
    // );
  }

  ngOnDestroy() {
    // this.onConfigChanged.unsubscribe();
  }
}
