import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { NkSplashScreenService } from './services/app-services/splash-screen.service';
import { LoadHelperStyleSheet } from './services/app-services/style-injector.service';
import { ThemesService } from './services/app-services/themes.service';

@Component({
  selector: 'nk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly nkSplashScreenService: NkSplashScreenService,
    private readonly themesService: ThemesService,
    public readonly store: Store
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    LoadHelperStyleSheet();
    this.themesService.init().subscribe();
    this.nkSplashScreenService.init().subscribe();
  }
}
