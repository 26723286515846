import { Ability, AbilityBuilder } from '@casl/ability';
import { ActiveUser } from './active-user.model';
import { ModulesNames } from './consts/modules.const';
import { ROLES } from './consts/roles.const';

export class ACLBuilder {
  static build(user: ActiveUser) {
    const ability = new AbilityBuilder();

    if (user.hasRole(ROLES.MASTER)) {
      ability.can('manage', 'all');
    } else if (user.hasRole(ROLES.SUPER_ADMIN)) {
      ability.can('manage', 'all', {});
    } else if (user.hasRole(ROLES.ADMIN)) {
      const mods = [
        // ModulesNames.DOCUMENTS,
        // ModulesNames.EVENTS,
        // ModulesNames.MESSENGER,
        // ModulesNames.NEWS,
        // ModulesNames.PROMOS,
        // ModulesNames.SLIDE,
      ];
      ability.can(['read', 'create'], mods);
      ability.can(['edit', 'delete'], mods, {
        author: { id: user.id },
      });
    } else if (user.hasRole(ROLES.USER)) {
      ability.can('read', 'all', {});
      // ability.cannot('read', ModulesNames.GROUPS);

      // ability.cannot('read', ModulesNames.CATS);
      ability.cannot('read', ModulesNames.USERS);
    }

    return new Ability(ability.rules as any);
  }
}
