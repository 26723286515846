import { TalkStore } from '../pages/talk/conversation/conversation.state';
import { NavigationLayoutSettingStore } from './app/navbar.state';
import { SideMenuStore } from './app/side-menu.state';
import { AppThemeStore } from './app/theme.state';

export const GLOBAL_STORES = [
  AppThemeStore,
  TalkStore,
  NavigationLayoutSettingStore,
  SideMenuStore,
];

export const GLOBAL_PERSISTENCE_STORES = [
  AppThemeStore,
  NavigationLayoutSettingStore,
];
