<div
  id="main-navigation"
  class="nav"
  [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}"
>
  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="layout === 'vertical'">
    <ng-container *ngFor="let item of navigation">
      <nk-nav-vertical-group
        *ngIf="item.type=='group'"
        [item]="item"
      ></nk-nav-vertical-group>
      <nk-nav-vertical-collapse
        *ngIf="item.type=='collapse'"
        [item]="item"
      ></nk-nav-vertical-collapse>
      <nk-nav-vertical-item
        *ngIf="item.type=='item'"
        [item]="item"
      ></nk-nav-vertical-item>
    </ng-container>
  </ng-container>
  <!-- / Vertical Navigation Layout -->
  <!-- Horizontal Navigation Layout -->
  <ng-container *ngIf="layout === 'horizontal'">
    <ng-container *ngFor="let item of navigation">
      <nk-nav-horizontal-collapse
        *ngIf="item.type=='group'"
        [item]="item"
      ></nk-nav-horizontal-collapse>
      <nk-nav-horizontal-collapse
        *ngIf="item.type=='collapse'"
        [item]="item"
      ></nk-nav-horizontal-collapse>
      <nk-nav-horizontal-item
        *ngIf="item.type=='item'"
        [item]="item"
      ></nk-nav-horizontal-item>
    </ng-container>
  </ng-container>
  <!-- / Horizontal Navigation Layout -->
</div>
