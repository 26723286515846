import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppAnimations } from '../../animations';
import { SnackBarService } from '../../services/app-services/snack-bar.service';
import { LoadingService } from '../../services/loading.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'nk-email-token',
  templateUrl: './email-token.component.html',
  styleUrls: ['./email-token.component.scss'],
  animations: AppAnimations,
})
export class EmailTokenComponent implements OnInit {
  public enterToken: FormGroup;
  public enterTokenError: any;
  public data;
  public resetData;
  public email;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly modalData: any,
    private readonly matDialogRef: MatDialogRef<EmailTokenComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly usersService: UsersService,
    private readonly loader: LoadingService,
    private readonly snackBar: SnackBarService,
    private readonly snackService: SnackBarService
  ) {
    this.email = modalData.profileData;
    console.log(this.email);
  }

  ngOnInit(): void {
    this.enterTokenError = { token: {} };
    this.enterToken = this.formBuilder.group({
      token: ['', [Validators.required]],
    });

    // this.enterEmailId.valueChanges.subscribe(() => {
    //   this.onLoginFormValuesChanged();
    // });
  }
  public verify() {
    this.resetData = this.enterToken.value;
    console.log(this.resetData);
    this.resetData.email = this.email;
    console.log(this.resetData);
    // this.loader.show();
    console.log(this.resetData);
    this.usersService.setEmail(this.resetData).subscribe(
      () => {
        this.snackBar.success('Email Updated Successfully');
        // this.router.navigate(['auth/login']);
        this.matDialogRef.close(true);
      },
      (error) => {
        this.snackService.error(error.error.message);
      }
    );
    // ConsoleLogger.log(this.setUpAccountForm.value());
  }
}
