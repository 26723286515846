<div
  id="login"
  fxLayout="column"
>
  <div style="float: left; font-weight: bold;">
    Email verification
  </div>
  <div
    id="login-form-wrapper"
    fxLayout="column"
  >
    <div [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }">
      <div id="login-form">
        <div
          class="logo"
          nkIcon="icon-papaya amber-fg w100p h100p"
        >
          <!--
          <svg class="icon icon-papaya amber-fg"
          style="width: 100%; height: 100%"><use xlink:href="assets/icons/icomoon/symbol-defs.svg#icon-papaya"></use></svg>
        -->
          <!--
          <mat-icon svgIcon="icon-papaya"
          starkSvgViewBox></mat-icon>
        -->
        </div>
        <!-- <div class="title">Forget Password</div> -->
        <form
          name="enterToken"
          [formGroup]="enterToken"
          (ngSubmit)="verify()"
          novalidate
        >
          <mat-form-field>
            <input
              type="number"
              matInput
              placeholder="Token"
              formControlName="token"
            />
            <mat-error *ngIf="enterTokenError.token.required">
              Token is required
            </mat-error>
            <mat-error *ngIf="enterTokenError.token.token">
              Please enter correct Token
            </mat-error>
          </mat-form-field>
          <button
            mat-raised-button
            color="accent"
            class="submit-button"
            aria-label="Continue"
            [disabled]="enterToken.invalid"
          >Continue</button>
        </form>
      </div>
    </div>
    <div>
      Please Check your email for the next process and token time only 10 minutes
    </div>
  </div>
</div>
